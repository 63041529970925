import MetaHelmet from '../../components/meta-helmet/MetaHelmet';
import AboutSection from './section/AboutSection';
import MissionSection from './section/MissionSection';
import TeamSection from './section/TeamSection';
import TestimonialSection from './section/TestimonialSection';

const AboutPage = () => (
  <main className="about-page container">
    <MetaHelmet title="About" />
    <AboutSection />
    <MissionSection />
    <TeamSection />
    <TestimonialSection />
  </main>
);

export default AboutPage;
