import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import English from './lang/en';
import German from './lang/de';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { lookupQuerystring: 'lang' },
    resources: {
      en: English,
      de: German,
    },
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
