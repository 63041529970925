import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TeamMembers } from '../../../constants/team.constants';
import './TeamSection.scss';

const LinkWrapper: FC<{ children: ReactNode; url: string }> = ({
  children,
  url,
}) => (
  <Link
    className="link-text"
    to={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </Link>
);

const TeamSection = () => {
  const { t } = useTranslation();
  return (
    <section className="team-panel">
      <h3 className="text-center mb-5">
        {t('about.team.title1')}
        <span className="text-primary">{t('about.team.title2')}</span>
      </h3>
      <div className="row justify-content-center">
        {TeamMembers.map((member) => (
          <div
            className="col-12 col-md-6 col-lg-4 team-member"
            key={member.name}
          >
            <div>
              <img alt="" src={window.location.origin + member.picture} />
              <div className="content d-flex flex-column align-items-center justify-content-center">
                <div className="name text-center">{member.name}</div>
                <div className="name role mt-1 mb-3">{member.role}</div>
                <div>
                  {member.twitter && (
                    <LinkWrapper url={member.twitter}>
                      <i className="bi bi-twitter" />
                    </LinkWrapper>
                  )}
                  {member.instagram && (
                    <LinkWrapper url={member.instagram}>
                      <i className="bi bi-instagram" />
                    </LinkWrapper>
                  )}
                  {member.linkedIn && (
                    <LinkWrapper url={member.linkedIn}>
                      <i className="bi bi-linkedin" />
                    </LinkWrapper>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TeamSection;
