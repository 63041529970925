import { useTranslation } from 'react-i18next';
import './SuccessStorySection.scss';

const Block = ({ title, detail }: { title: string; detail: string }) => (
  <div className="success-block">
    <div className="title">{title}</div>
    <div className="detail">{detail}</div>
  </div>
);

const SuccessStorySection = () => {
  const { t } = useTranslation();
  return (
    <section className="success-story-panel row justify-content-center align-items-center">
      <h3 className="text-center mb-4 px-lg-5">
        {t('home.successStory.title1')}
        <span className="text-primary">{t('home.successStory.title2')}</span>
        {t('home.successStory.title3')}
      </h3>
      <div className="d-none d-lg-flex col-lg-5">
        <div className="success-intro-pic-parent ratio ratio-1x1">
          <img
            alt="Success Story"
            className="success-intro-pic"
            src={
              window.location.origin + '/assets/images/home-success-story.webp'
            }
          />
        </div>
      </div>
      <div className="col-12 col-lg-7 h-100 d-flex flex-column  justify-content-center">
        <div className="description text-start mt-0">
          {t('home.successStory.description')}
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mb-3 mb-md-0">
            <Block
              title="1000+"
              detail={t('home.successStory.consultations')}
            />
          </div>
          <div className="col-12 col-md-6">
            <Block title="40+" detail={t('home.successStory.companies')} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStorySection;
