import { Route, Routes, Navigate } from 'react-router';
import { Routes as RouteConstant } from '../constants/nav.constant';
import AboutPage from '../pages/about-page/AboutPage';
import HomePage from '../pages/home-page/HomePage';
import JobBoardPage from '../pages/job-board-page/JobBoardPage';
import NotFoundPage from '../pages/not-found-page/NotFoundPage';
import ServicePage from '../pages/service-page/ServicePage';
import Layout from './layout/Layout';
import ContactUsPage from '../pages/contact-us-page/ContactUsPage';

const Router = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path={RouteConstant.home} element={<HomePage />} />
      <Route path={RouteConstant.about} element={<AboutPage />} />
      <Route path={RouteConstant.services} element={<ServicePage />} />
      <Route
        path={RouteConstant.services + '/:serviceId'}
        element={<ServicePage />}
      />
      <Route path={RouteConstant.jobBoard} element={<JobBoardPage />} />
      <Route
        path={RouteConstant.jobBoard + '/:jobId'}
        element={<JobBoardPage />}
      />
      <Route path={RouteConstant.contact} element={<ContactUsPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
  </Routes>
);

export default Router;
