/* eslint-disable import/no-anonymous-default-export */
export default {
  translation: {
    job: {
      STEM: {
        title: 'STEM Talent Solutions',
        detail: [
          "Navigating the STEM job market in Germany can be a daunting task, especially for international candidates. At Doorway International, we specialize in STEM recruitment services, connecting talented individuals with exciting career opportunities in Germany's thriving STEM sectors. Our team of experts understands the unique challenges faced by international candidates in this competitive field and is dedicated to providing tailored support throughout the recruitment process.",
          "With our extensive network of industry contacts and deep understanding of the German job market, we can identify the most suitable STEM positions that align with your skills, experience, and career goals. We go beyond traditional job boards, leveraging our connections with top companies and research institutions to uncover hidden opportunities that may not be readily available to the public. Whether you're a recent STEM graduate or an experienced professional seeking new horizons, our comprehensive STEM recruitment services streamline your job search and enhance your chances of securing a fulfilling position in Germany.",
          "From assisting with CV optimization and interview preparation to providing guidance on visa requirements and cultural integration, our team is committed to supporting you at every step of the way. We recognize the immense value that international candidates bring to the German STEM landscape, and our goal is to facilitate your seamless integration into the country's vibrant scientific and technological community. Trust Doorway International to be your partner in unlocking the doors to exciting STEM career opportunities in Germany.",
        ],
      },
      Apprentice: {
        title: 'Apprentice Placement Services',
        detail: [
          'Experience World-Class Apprenticeships in Germany: Earn While You Learn!',
          'Germany\'s renowned "Ausbildung" program offers some of the best apprenticeships in the world, combining 60% practical training with 40% theory. What\'s more, apprentices receive a stipend ranging from 700 to 1200 euros, making it an incredible opportunity for career development. At Doorway International, we are proud to offer apprenticeships with stipends in a variety of fields, including IT, Mechanical Engineering, Mechatronics, Electrical Engineering, Nursing, and Gastronomy.',
          'With our extensive network of partner companies and educational institutions, we provide aspiring apprentices with access to exceptional training opportunities. Gain hands-on experience working alongside industry professionals, while also receiving theoretical knowledge through classroom instruction. This unique blend of practical and theoretical learning equips apprentices with the skills and knowledge necessary to excel in their chosen fields.',
          'Not only do apprenticeships in Germany provide invaluable training, but they also come with financial support. The stipend provided during the apprenticeship period ensures that apprentices can cover their living expenses while focusing on their professional development.',
          'Whether you aspire to become an IT specialist, mechanical engineer, electrician, nurse, or gastronomy professional, our apprenticeship programs open doors to exciting career paths. At Doorway International, we are committed to helping international candidates secure apprenticeships that align with their interests and goals.',
          'Embark on a rewarding journey of personal and professional growth with our apprenticeship programs in Germany. Gain practical skills, earn a stipend, and pave the way for a successful future in your chosen field. Partner with Doorway International to unlock world-class apprenticeships and embark on a transformative career path.',
        ],
      },
      Healthcare: {
        title: 'Healthcare Staffing Experts',
        detail: [
          'Join the Healthcare Elite in Germany: Thrive as a Nurse or Healthcare Worker',
          'Germany offers exceptional opportunities for nurses and healthcare workers, making it a highly sought-after destination in the healthcare industry. As a nurse or healthcare professional, you can expect a rewarding career with competitive salaries, excellent benefits, and a high standard of working conditions.',
          'At Doorway International, we specialize in connecting talented nurses and healthcare workers with top-notch healthcare facilities in Germany. Whether you are a registered nurse, healthcare assistant, or specialized medical professional, we have opportunities available in various healthcare settings, including hospitals, clinics, nursing homes, and rehabilitation centers.',
          "Germany's healthcare system is renowned for its emphasis on patient care, cutting-edge medical technology, and continuous professional development. By joining the healthcare elite in Germany, you will have access to state-of-the-art facilities, advanced medical resources, and a supportive working environment that fosters growth and innovation.",
          'As an international candidate, we provide comprehensive support throughout the entire process, including assistance with visa requirements, language preparation, and cultural integration. Our team understands the unique challenges that international nurses and healthcare workers face, and we are committed to making your transition to Germany as smooth as possible.',
          "Whether you are seeking a permanent position or a temporary assignment, Doorway International has a wide range of opportunities available in the nursing and healthcare sector. Take the next step in your career and unlock the incredible professional and personal growth that awaits you in Germany's esteemed healthcare industry.",
          'Join us at Doorway International and embark on a fulfilling journey as a valued member of the healthcare workforce in Germany. Your skills, dedication, and compassion will make a difference in the lives of patients, while offering you a rewarding and prosperous career path.',
        ],
      },
      Resume: {
        title: 'Professional CV writing',
        detail: [
          'At Doorway International, we are dedicated to supporting your career success. As part of our commitment, we offer professional CV writing services absolutely free of charge. Our team of expert career advisors and CV writers will work closely with you to craft a compelling CV that highlights your skills, experiences, and achievements.',
          'With our in-depth understanding of the job market and industry-specific requirements, we ensure that your CV stands out from the competition. We tailor your CV to showcase your unique strengths and qualifications, increasing your chances of securing interviews and landing your dream job.',
          "By taking advantage of our free CV writing service, you can present yourself effectively to employers and make a lasting impression. We understand the importance of a well-crafted CV in today's competitive job market, and we are here to help you make a strong impact. Don't miss out on this valuable opportunity to elevate your job search. Contact us today to take advantage of our professional CV writing service, provided to you at no cost. Let us help you create a CV that opens doors to exciting career opportunities.",
          "Don't miss out on this valuable opportunity to elevate your job search. Contact us today to take advantage of our professional CV writing service, provided to you at no cost. Let us help you create a CV that opens doors to exciting career opportunities.",
        ],
      },
      Career: {
        title: 'Career Advancement Coaching',
        detail: [
          'At Doorway International, we are dedicated to supporting your career success. As part of our commitment, we offer professional CV writing services absolutely free of charge. Our team of expert career advisors and CV writers will work closely with you to craft a compelling CV that highlights your skills, experiences, and achievements.',
          'With our in-depth understanding of the job market and industry-specific requirements, we ensure that your CV stands out from the competition. We tailor your CV to showcase your unique strengths and qualifications, increasing your chances of securing interviews and landing your dream job.',
          "By taking advantage of our free CV writing service, you can present yourself effectively to employers and make a lasting impression. We understand the importance of a well-crafted CV in today's competitive job market, and we are here to help you make a strong impact.",
          "Don't miss out on this valuable opportunity to elevate your job search. Contact us today to take advantage of our professional CV writing service, provided to you at no cost. Let us help you create a CV that opens doors to exciting career opportunities.",
        ],
      },
      Branding: {
        title: 'Employer Branding Solutions',
        detail: [
          'Elevate Your Employer Brand with Tailored Solutions',
          'At Doorway International, we understand the importance of a strong employer brand in attracting and retaining top talent. Our employer branding solutions are designed to help your organization stand out in the competitive job market, positioning you as an employer of choice.',
          'We work closely with your company to develop a comprehensive employer branding strategy that aligns with your values, culture, and goals. Our team of experts conducts in-depth research to understand your unique selling points and differentiators, crafting a compelling employer brand message that resonates with your target audience.',
          'Through our tailored solutions, we enhance your employer brand visibility across multiple channels, including social media, job boards, and career websites. We create engaging content that showcases your company culture, values, employee testimonials, and career opportunities, effectively communicating your employer brand story.',
          "Our employer branding solutions go beyond just attracting talent. We also focus on employee engagement and retention, implementing strategies to create a positive and inclusive work environment. By fostering a strong employer brand, you can attract top talent, enhance employee loyalty, and boost your company's overall reputation.",
          'Whether you are a small startup or a large organization, our employer branding solutions are customizable to meet your specific needs and budget. We are committed to partnering with you to strengthen your employer brand and create a compelling narrative that will resonate with both current and future employees.',
          'Let Doorway International be your trusted partner in developing and executing effective employer branding solutions. Together, we will elevate your employer brand, attract top talent, and drive the success of your organization.',
        ],
      },
    },
    home: {
      intro: {
        title1: 'Unlock Your Dream Career in Germany:',
        title2: ' Discover Opportunities, Excel, and Thrive!',
        description:
          'Are you longing for a rewarding career in the vibrant and dynamic job market of Germany? Look no further! Our comprehensive platform is tailored to help ambitious individuals like you find their dream career in Germany, opening doors to a world of exciting possibilities.',
        discoverJobButton: 'Discover Jobs ',
        discoverTalentButton: 'Discover Top Talents',
      },
      service: {
        title1: 'What we do for ',
        title2: 'you',
        description:
          'Unlock Your Dream Career in Germany with our Expert Job Search Assistance and Integration Coaching. Seamlessly navigate the German job market and secure the perfect job with our tailored services.',
      },
      successStory: {
        title1: 'Your Path to Success: ',
        title2: 'Unlocking Opportunities',
        title3:
          ' for International Candidates - Leading Job Placement Organization in Germany',
        description:
          'Open Doors to Success: Leading the Way for International Candidates - Premier Job Placement Organization in Germany. Find your dream career with our expert guidance and connections in the German job market.',
        consultations: 'consultations',
        companies: 'companies',
      },
    },
    about: {
      intro: {
        title1: 'Our ',
        title2: 'Story',
        detail: [
          'At Doorway International, our mission is simple: to help people find their dream jobs in Germany."In the realm of foreign students navigating the German job market, Deeban Mathivanan encountered numerous obstacles while searching for employment opportunities. Determined to make a difference, he embarked on a mission to support international students in their quest to find jobs in Germany. Simultaneously, Robert Sadowski, a seasoned career coach with over seven years of experience, recognized the unique challenges faced by foreign applicants in the German job market. Whether it was language barriers, CV writing, interview strategies, or navigating the probationary period, Robert understood the need for specialized guidance.',
          'In 2019, fate brought Deeban and Robert together. Sharing a common goal and complementary skills, they decided to establish Doorway International, a company dedicated to assisting international candidates in securing jobs in Germany. Deeban, armed with a Masters in Electrical Engineering and an MBA from OVGU, Magdeburg, brought a wealth of knowledge and experience from working with prestigious organizations like the DLR and the University of Stuttgart, as well as a recruitment company in Berlin. On the other hand, Robert, having embarked on a career supporting applicants after obtaining a Magister of Health from Dresden University and also an MBA from OVGU Business School later. He possessed the empathy and expertise required to guide individuals towards job success.',
          "Together, they built Doorway International as a bridge connecting talented individuals from around the world with career opportunities in Germany. Their professional approach, combined with relatability, resonated with countless candidates, inspiring them to overcome challenges and achieve remarkable success. Through their guidance, language skills were honed, compelling CVs were crafted, interview techniques were refined, and the probationary period was conquered with confidence. Doorway International's reputation as a trusted partner for international candidates seeking success in the German job market grew, fueled by the transformative journeys of those they supported.",
          'Today, the founding story of Deeban Mathivanan and Robert Sadowski serves as a testament to the power of resilience, expertise, and shared vision. Doorway International continues to shape the destinies of international candidates, inviting them to embark on a remarkable path to success.',
        ],
      },
      mission: {
        title1: 'Our ',
        title2: 'Mission',
        statement:
          'Our vision at Doorway International is to empower individuals from around the world by connecting them with meaningful career opportunities, while partnering with companies to build diverse and high-performing teams.',
      },
      vision: {
        title1: 'Our ',
        title2: 'Values',
        statement:
          'Excellence, Integrity, Empowerment, Diversity & Inclusion, Collaboration, Innovation, Impact - These core values drive our mission to help international candidates integrate into a foreign land, find their dream job, and enable companies to discover exceptional talent.',
      },
      team: {
        title1: 'Our ',
        title2: 'Team',
      },
      testimonials: {
        title1: 'Our ',
        title2: 'Testimonials',
      },
    },
    service: {
      title: 'What we do for you',
      description:
        'We provide personalized job search support, expert coaching, and tailored resources to help you secure your dream job in Germany. Our dedicated team is committed to empowering your career growth and ensuring a smooth integration into the German job market.',
    },
    jobBoard: 'Job board',
    contact: 'Contact us',
    today: 'today',
    ConstactDesc1: 'We’re always here to help.',
    ConstactDesc2: 'Contact us if you have any questions.',
    menu: {
      home: 'Home',
      about: 'About Us',
      services: 'Services',
      jobBoard: 'Job Board',
      contact: 'Contact Us',
    },
    footer: {
      contactInfo: 'Contact Info',
    },
  },
};
