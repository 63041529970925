import { Link } from 'react-router-dom';
import { FC } from 'react';

const LinkWrapper: FC<UrlSet> = ({ name, url }) => (
  <Link
    className="link-text"
    to={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    {name}
  </Link>
);

export default LinkWrapper;
