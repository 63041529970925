import { useEffect, useState } from 'react';
import usePageTracking from './hook/usePageTracking';
import Router from './route/Router';
import Modal from 'react-modal';
import ReactGA from 'react-ga4';
import { useLocalStorage } from './hook/useLocalStorage';
import {
  CONSENT_OPTION,
  CONSENT_LOCAL_STORAGE,
} from './constants/consent.constant';

import './App.scss';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap-icons/font/bootstrap-icons.css';

const App = () => {
  const [showConsentModal, setShowConsentModal] = useState<boolean>(false);
  const [consentValue, setConsentValue] = useLocalStorage<CONSENT_OPTION>(
    CONSENT_LOCAL_STORAGE,
    CONSENT_OPTION.NOT_SET,
  );

  usePageTracking();

  useEffect(
    () =>
      setShowConsentModal(
        !(
          consentValue === CONSENT_OPTION.ACCEPTED ||
          consentValue === CONSENT_OPTION.REJECTED
        ),
      ),
    [],
  );

  useEffect(() => {
    if (consentValue === CONSENT_OPTION.ACCEPTED) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MATCH_ID ?? '');
    }
  }, [consentValue]);

  const onAccept = () => {
    setConsentValue(CONSENT_OPTION.ACCEPTED);
    setShowConsentModal(false);
  };

  const onReject = () => {
    setConsentValue(CONSENT_OPTION.REJECTED);
    setShowConsentModal(false);
  };

  return (
    <div className="App text-secondary">
      <Router />
      <Modal isOpen={showConsentModal} className="consent-modal p-3">
        <h6>Privacy Settings</h6>
        <div className="consent-text">
          This website uses cookies that are strictly necessary for the
          technical operation of the website and are always set. Other cookies
          requiring consent, to personalize content and ads and to analyse
          access to our website, are set only with your consent. We also share
          information about your use of our website with our social media,
          advertising and analytics partners.
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-link btn-sm me-2"
            onClick={onReject}
          >
            Deny
          </button>

          <button
            type="button"
            className="btn btn-primary btn-sm ms-2"
            onClick={onAccept}
          >
            Accept
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default App;
