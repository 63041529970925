import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/logo/Logo';
import {
  ContactList,
  NavigationUrlSets,
  SocialMediaUrlSets,
  TermsUrlSets,
} from '../../constants/footer.constant';
import './Footer.scss';

const LinkWrapper: FC<UrlSet> = ({ name, url }) => (
  <Link
    className="link-text"
    to={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    {name}
  </Link>
);

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="w-100 bg-secondary text-white">
      <div className="container">
        <div className="row">
          <section className="col-12 col-lg-6 d-flex flex-column">
            <Logo isDark />
            <div className="d-flex mt-3">
              {SocialMediaUrlSets.map((urlSet) => (
                <Link
                  className="me-2"
                  to={urlSet.url}
                  key={urlSet.name}
                  target="_blank"
                  aria-label="Check out social media"
                  rel="noopener noreferrer"
                >
                  <i className={`bi ${urlSet.iconClassName} fs-2`} />
                </Link>
              ))}
            </div>
          </section>
          <section className="col-12 col-lg-2">
            <div className="header">Navigation</div>
            {NavigationUrlSets.map((urlSet) => (
              <LinkWrapper
                key={urlSet.name}
                name={t(urlSet.name)}
                url={urlSet.url}
              />
            ))}
          </section>
          {/* <section className="col-12 col-md-6 col-lg-2">
          <div className="header">Resources</div>
          {ResourceUrlSets.map((urlSet) => (
            <LinkWrapper {...urlSet} />
          ))}
        </section> */}
          <section className="col-12 col-lg-4">
            <div className="header">{t('footer.contactInfo')}</div>
            {ContactList.map((urlSet) => (
              <div className="link-text" key={urlSet.name}>
                <i className={`bi ${urlSet.iconClassName}`} />
                <LinkWrapper {...urlSet} />
              </div>
            ))}
          </section>
        </div>
        <div className="divider" />
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between mb-2 mt-4">
          <div className="d-flex flex-column flex-lg-row terms-condition-parent w-100 w-lg-auto align-items-center">
            {TermsUrlSets.map((urlSet) => (
              <LinkWrapper {...urlSet} key={urlSet.name} />
            ))}
          </div>
          <div className="text-center text-nowrap">{`© ${new Date().getFullYear()} Doorway International Gbr. All rights reserved.`}</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
