import { useTranslation } from 'react-i18next';
import './MissionSection.scss';

const MissionSection = () => {
  const { t } = useTranslation();
  return (
    <section className="mission-panel d-flex flex-column  align-items-center justify-content-center px-4">
      <h3 className="text-start">
        {t('about.mission.title1')}
        <span className="text-primary">{t('about.mission.title2')}</span>
      </h3>
      <blockquote className="description text-center">
        {t('about.mission.statement')}
      </blockquote>
      <h3 className="text-start pt-5">
        {t('about.vision.title1')}
        <span className="text-primary">{t('about.vision.title2')}</span>
      </h3>
      <div className="description text-center">
        {t('about.vision.statement')}
      </div>
    </section>
  );
};

export default MissionSection;
