import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ServiceList } from '../../../constants/service.constant';
import './ServiceIntroSection.scss';

const ServiceIntroSection = () => {
  const { t } = useTranslation();
  return (
    <section className="services-intro-panel row  justify-content-center">
      <div className="col-12 col-xl-7">
        <h2 className="text-start">
          {t('home.service.title1')}
          <span className="text-primary">{t('home.service.title2')}</span>
        </h2>
        <div className="description text-start">
          {t('home.service.description')}
        </div>
        <div className="row mt-4">
          {ServiceList.map((service) => (
            <Link
              className="service-link col-12 col-md-6 text-start mb-4"
              to={service.path}
              key={service.name}
            >
              {t(service.name + '.title')} <i className="bi bi-chevron-right" />
            </Link>
          ))}
        </div>
      </div>
      <div className="service-intro-pic-panel col-5 position-relative d-none d-xl-flex">
        <div className="pic-container mx-auto my-auto ratio ratio-1x1">
          <div className="bg-shadow" />
          <img
            alt="Service Intro"
            className="service-intro-pic ratio ratio-1x1"
            src={window.location.origin + '/assets/images/home-services.webp'}
          />
        </div>
      </div>
    </section>
  );
};

export default ServiceIntroSection;
