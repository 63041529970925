/* eslint-disable react/button-has-type */
import MetaHelmet from '../../components/meta-helmet/MetaHelmet';
import './HomePage.scss';
import IntroductionSection from './section/IntroductionSection';
import ServiceIntroSection from './section/ServiceIntroSection';
import SuccessStorySection from './section/SuccessStorySection';

const HomePage = () => {
  return (
    <main className="home-page container">
      <MetaHelmet />
      <IntroductionSection />
      <ServiceIntroSection />
      <SuccessStorySection />
    </main>
  );
};

export default HomePage;
