import { Routes, ServicesRouteTag } from './nav.constant';

export const ServiceList = [
  {
    name: 'job.STEM',
    path: Routes.stemRecruitmentService,
    tag: ServicesRouteTag.stemRecruitment,
  },
  {
    name: 'job.Apprentice',
    path: Routes.apprenticeshipRecruitmentService,
    tag: ServicesRouteTag.apprenticeshipRecruitment,
  },
  {
    name: 'job.Healthcare',
    path: Routes.nursingRecruitmentService,
    tag: ServicesRouteTag.nursingRecruitment,
  },
  {
    name: 'job.Resume',
    path: Routes.resumeAndInterviewCoachingService,
    tag: ServicesRouteTag.resumeAndInterviewCoaching,
  },
  {
    name: 'job.Career',
    path: Routes.careerDevelopmentCoachingService,
    tag: ServicesRouteTag.careerDevelopmentCoaching,
  },
  {
    name: 'job.Branding',
    path: Routes.employerBrandingAndRecruitmentMarketingService,
    tag: ServicesRouteTag.employerBrandingAndRecruitmentMarketing,
  },
];
