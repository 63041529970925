import { PopupButton } from '@typeform/embed-react';
import { FC } from 'react';

const JobView: FC<{
  job: Job;
  onClick: (job: Job) => void;
}> = ({ job, onClick }) => (
  <div className="col-12 col-lg-6">
    <div
      className="card job-card my-2 p-3 d-flex flex-column flex-md-row justify-content-between align-items-md-center cursor-pointer"
      onClick={(event) => {
        onClick(job);
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div className="logo me-3 bg-primary d-none d-md-flex align-items-center justify-content-center">
        <i className="bi bi-building text-white"></i>
      </div>
      <div className="flex-fill">
        <div className="name mb-2">{job.name}</div>
        {/* <div className="company-name mb-2">{job.companyName}</div> */}
        <div className="row w-100">
          <div className="col-6 col-md-4 col-lg-6 col-xl-3 detail text-nowrap d-flex align-items-center">
            <i className="bi bi-geo-alt"></i>
            {job.location}
          </div>
          <div className="col-6 col-md-4 col-lg-6 col-xl-5 detail text-nowrap">
            <i className="bi bi-briefcase"></i>
            {job.experience}
          </div>
          <div className="col-6 col-md-4 col-lg-6 col-xl-3 detail text-nowrap">
            <i className="bi bi-buildings"></i>
            {job.type}
          </div>
          {/* <LinkWrapper url={job.descriptionFile} name="Read more..." /> */}
        </div>
      </div>

      <div
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <PopupButton
          id={process.env.REACT_APP_TYPEFORM_FORM_ID ?? ''}
          className="btn btn-primary mt-3 mt-md-0"
          hidden={{
            job_profile: job.name,
          }}
        >
          Apply
        </PopupButton>
      </div>
    </div>
  </div>
);

export default JobView;
