import { Outlet } from 'react-router';
import ScrollToTop from '../../components/scroll-to-top/ScrollToTop';
import Footer from '../Footer/Footer';
import NavBar from '../nav-bar/NavBar';

import './Layout.scss';

const Layout = () => {
  return (
    <div className="layout" id="main">
      <ScrollToTop />
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
