import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ServiceList } from '../../constants/service.constant';

import {
  ServicesImageMapper,
  ServicesImageThumbMapper,
  ServicesRouteTagNameMapper,
} from '../../constants/nav.constant';
import './ServicePage.scss';
import MetaHelmet from '../../components/meta-helmet/MetaHelmet';

const ServicePage = () => {
  const { t } = useTranslation();

  let { serviceId } = useParams();
  let navigate = useNavigate();

  const serviceName = ServicesRouteTagNameMapper[serviceId ?? ''];

  const serviceImage = ServicesImageMapper[serviceId ?? ''];
  const serviceDescription = serviceName
    ? t(serviceName + '.detail', { joinArrays: '\n\n' })
    : '';

  if (serviceId && !serviceName) navigate('/');

  return (
    <main className="service-page">
      <MetaHelmet
        title={serviceName ? t(serviceName + '.title') : 'Sevices'}
        description={serviceDescription ? t('service.description') : undefined}
        image={serviceImage}
      />
      <section className="section-header w-100 position-relative navbar-padding">
        {serviceImage && (
          <img
            className="fixed-position-full section-header w-100"
            alt=""
            src={window.location.origin + serviceImage}
          />
        )}
        <div
          className={
            'fixed-position-full d-flex flex-column align-items-center justify-content-center text-center text-primary ' +
            (serviceImage ? 'bg-transparent-secondary' : 'bg-secondary')
          }
        >
          <h1 className="mt-5 pt-3">
            {serviceName ? t(serviceName + '.title') : t('service.title')}
          </h1>
          {!serviceName && (
            <span className="description mt-3 text-center d-none d-md-block">
              {t('service.description')}
            </span>
          )}
        </div>
      </section>
      <section className="service-list container">
        {serviceName ? (
          <div className="section-detail text-justify">
            {serviceDescription}
          </div>
        ) : (
          <div className="row">
            {ServiceList.map((service) => {
              return (
                <div className="col-12 col-lg-6" key={service.name}>
                  <div className="service-item text-center text-secondary d-flex flex-column justify-content-between">
                    <img
                      alt=""
                      src={
                        window.location.origin +
                        ServicesImageThumbMapper[service.tag]
                      }
                    />
                    <div className="service-item-text-wrapper">
                      <div>
                        <div className="title">
                          {t(service.name + '.title')}
                        </div>
                        <div className="detail">
                          {t(service.name + '.detail', { joinArrays: ' ' })}
                        </div>
                      </div>
                      <div>
                        <Link
                          to={service.path}
                          className="text-primary learn-more-button"
                        >
                          Learn more <i className="bi bi-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
    </main>
  );
};

export default ServicePage;
