import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ContactList,
  SocialMediaUrlSets,
} from '../../../constants/footer.constant';
import LinkWrapper from '../../../components/link-wrapper/LinkWrapper';
import './ContactInfoSection.scss';

const ContactInfoSection = () => {
  const { t } = useTranslation();
  return (
    <section className="contact-info-container navbar-padding  col-12 col-lg-6 d-flex flex-column justify-content-center h-100">
      <div className="contact-info">
        <h3 className="text-start pt-5">
          {t('contact')} <span className="text-primary">{t('today')}</span>
        </h3>
        <div className="description mt-2 mb-0">
          {t('ConstactDesc1')}
          <br />
          {t('ConstactDesc2')}
        </div>
        <div className="d-flex my-4">
          {SocialMediaUrlSets.map((urlSet) => (
            <Link
              className="social-icons me-3 d-flex align-items-center justify-content-center"
              to={urlSet.url}
              target="_blank"
              aria-label="Check out social media"
              rel="noopener noreferrer"
              key={urlSet.name}
            >
              <i className={`bi ${urlSet.iconClassName} text-white`} />
            </Link>
          ))}
        </div>
        {ContactList.map((urlSet) => (
          <div
            className="link-text mt-1 d-flex align-items-center"
            key={urlSet.name}
          >
            <i className={`bi ${urlSet.iconClassName} me-2 `} />
            <LinkWrapper {...urlSet} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ContactInfoSection;
