import ElectricalEnginnerControlAndTelecontrolJob from '../data/electrical-engineer(control-and-telecontrol-technology).json';
import EngineerPipelineConstructionJob from '../data/engineer-for-pipeline-projects.json';
import SeniorAndroidDeveloperJob from '../data/senior-android-developer.json';
import PHPDeveloperJob from '../data/php-developer.json';
import ProcessEngineerJob from '../data/process-engineer.json';
import ServerSystemAdministratorJob from '../data/server-administrator-system-administrator.json';

import ApplicationDevelopmentSupportJob from '../data/application-develpoment-support.json';
import ApplicationEngineerJob from '../data/application-engineer.json';
import AutomationTechnologyEngineerJob from '../data/automation-technology-engineer.json';
import BusinessIntelligenceProfessionalJob from '../data/business-intelligence-professional.json';
import DataEngineerJob from '../data/data-engineer.json';
import DataManagerProductManagmentJob from '../data/data-manager-in product-management.json';
import DesignEngineerJob from '../data/design-engineer.json';
import DevelopmentEngineerDeveiceDevelopmentJob from '../data/development-engineer-device-development.json';
import DevelopmentEngineerDeveiceAndSystemJob from '../data/development-engineer-devices-and-system-engineering.json';
import DevelopmentEngineerDigitalTwimSimulationJob from '../data/development-engineer-digital-twins-simulation.json';
import DevelopmentEngineerHardwareElectronicsJob from '../data/development-engineer-hardware-electronics.json';
import EngineeringManager2Job from '../data/engineering-manager-2.json';
import EngineeringManagerJob from '../data/engineering-manager.json';
import JuniorSecurityAnalystJob from '../data/junior-security-analyst.json';
import JuniorSalesEngineerJob from '../data/junior-sales-engineer.json';
import MechanicalEngineerRecyclingJob from '../data/mechanical-engineer-recycling.json';
import MobileDeveloperJob from '../data/mobile-developer.json';
import ModelingMriSystemJob from '../data/modeling-mri-systems.json';
import PlcProgrammerJob from '../data/plc-programmer.json';
import ProductManagerJob from '../data/product-manager.json';
import ProductionLogisticianJob from '../data/production-logistician.json';
import ProjectManagerJob from '../data/project manager.json';
import SalesManagerInternationalJob from '../data/sales-manager-international.json';
import SalesManagerTechnicalSalesEngineerJob from '../data/sales-manager-technical-sales-engineer.json';
import SystemAdministratorJob from '../data/system-administrator.json';
import ITServiceTechnicianJob from '../data/it-service-technician.json';

export enum JobType {
  OnSite = 'On-site',
  Remote = 'Remote',
  Hybrid = 'Hybrid',
}
export enum JobExperience {
  EntryLevel = 'Entry level',
  MidSenior = 'Mid-Senior level',
  Executive = 'Executive',
}

const generateSearchQuery = (job: Job): Job => {
  const searchQuery = (
    job.name +
    ' ' +
    job.experience +
    ' ' +
    job.location +
    ' ' +
    job.type
  ).replace(/[^a-z0-9 ]/gi, '');
  return { ...job, searchQuery: searchQuery };
};

export const JobData: Job[] = [
  generateSearchQuery(ElectricalEnginnerControlAndTelecontrolJob),
  generateSearchQuery(EngineerPipelineConstructionJob),
  generateSearchQuery(SeniorAndroidDeveloperJob),
  generateSearchQuery(PHPDeveloperJob),
  generateSearchQuery(ProcessEngineerJob),
  generateSearchQuery(ServerSystemAdministratorJob),
  generateSearchQuery(ApplicationDevelopmentSupportJob),
  generateSearchQuery(ApplicationEngineerJob),
  generateSearchQuery(AutomationTechnologyEngineerJob),
  generateSearchQuery(BusinessIntelligenceProfessionalJob),
  generateSearchQuery(DataEngineerJob),
  generateSearchQuery(DataManagerProductManagmentJob),
  generateSearchQuery(DesignEngineerJob),
  generateSearchQuery(DevelopmentEngineerDeveiceDevelopmentJob),
  generateSearchQuery(DevelopmentEngineerDeveiceAndSystemJob),
  generateSearchQuery(DevelopmentEngineerDigitalTwimSimulationJob),
  generateSearchQuery(DevelopmentEngineerHardwareElectronicsJob),
  generateSearchQuery(EngineeringManager2Job),
  generateSearchQuery(EngineeringManagerJob),
  generateSearchQuery(JuniorSecurityAnalystJob),
  generateSearchQuery(JuniorSalesEngineerJob),
  generateSearchQuery(MechanicalEngineerRecyclingJob),
  generateSearchQuery(MobileDeveloperJob),
  generateSearchQuery(ModelingMriSystemJob),
  generateSearchQuery(PlcProgrammerJob),
  generateSearchQuery(ProductManagerJob),
  generateSearchQuery(ProductionLogisticianJob),
  generateSearchQuery(ProjectManagerJob),
  generateSearchQuery(SalesManagerInternationalJob),
  generateSearchQuery(SalesManagerTechnicalSalesEngineerJob),
  generateSearchQuery(SystemAdministratorJob),
  generateSearchQuery(ITServiceTechnicianJob),
];

export const JobDataMapper: Record<string, Job> = JobData.reduce(
  (obj, cur) => ({ ...obj, [cur.id]: cur }),
  {},
);
