import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './IntroductionSection.scss';
import { Routes } from '../../../constants/nav.constant';

const IntroductionSection = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  return (
    <section className="introduction-panel  d-flex align-items-center justify-content-center mb-5 navbar-padding">
      <div className="introduction-content d-flex flex-column justify-content-center flex align-items-center w-100 mx-auto px-3">
        <h2 className="text-center">
          <span className="text-primary">{t('home.intro.title1')}</span>
          {t('home.intro.title2')}
        </h2>
        <div className="description">{t('home.intro.description')}</div>
        <div className="d-flex flex-column flex-md-row mt-4">
          <button
            type="button"
            className="btn btn-outline-primary px-5 mx-md-2"
            onClick={() => navigate(Routes.contact)}
          >
            {t('home.intro.discoverTalentButton')}
          </button>
          <button
            type="button"
            className="btn btn-lg btn-primary  btn-lg px-5 mt-3 mt-md-0 mx-md-2"
            onClick={() => navigate(Routes.jobBoard)}
          >
            {t('home.intro.discoverJobButton')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default IntroductionSection;
