import { FC } from 'react';

const AppLogo: FC<{ height?: string; width?: string }> = ({
  height,
  width,
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1348 379"
    xmlSpace="preserve"
  >
    <path
      fill="#FFFFFF"
      opacity="0.000000"
      stroke="none"
      d="
M1041.000000,380.000000 
C694.000061,380.000000 347.500092,380.000000 1.000093,380.000000 
C1.000062,253.666718 1.000062,127.333443 1.000031,1.000127 
C450.333221,1.000085 899.666443,1.000085 1348.999756,1.000042 
C1348.999756,127.333214 1348.999756,253.666428 1348.999878,379.999817 
C1246.500000,380.000000 1144.000000,380.000000 1041.000000,380.000000 
M265.048584,349.876831 
C267.964294,360.561798 273.991852,365.145508 285.354767,365.145813 
C628.187500,365.154572 971.020203,365.154663 1313.852905,365.146606 
C1327.470337,365.146301 1334.777466,357.863068 1334.823364,344.153992 
C1334.889526,324.320923 1334.714722,304.486389 1334.884644,284.654541 
C1334.998291,271.413422 1326.258789,263.670715 1313.823975,263.679749 
C1019.658020,263.893555 725.491821,263.845856 431.325714,263.845978 
C382.159149,263.846008 332.992554,263.834656 283.825989,263.854706 
C272.659790,263.859253 264.969360,271.030853 264.904724,281.995361 
C264.773041,304.326324 264.932526,326.659027 265.048584,349.876831 
M83.499939,26.165304 
C127.324127,26.165289 171.148315,26.164486 214.972504,26.165827 
C225.502975,26.166147 229.832901,30.459703 229.833176,40.933426 
C229.835831,142.913223 229.834061,244.893005 229.833588,346.872803 
C229.833572,348.478821 229.833572,350.084869 229.833572,352.111267 
C222.358002,352.111267 215.435593,352.111267 208.427704,352.111267 
C208.427704,356.618408 208.427704,360.693604 208.427704,364.829651 
C220.062683,364.829651 231.434219,364.829651 242.889542,364.829651 
C242.935669,363.847626 242.993011,363.196320 242.993042,362.544983 
C242.997345,254.566376 243.018219,146.587753 242.974197,38.609184 
C242.968048,23.544636 232.183151,13.018212 216.988205,13.012027 
C158.499786,12.988219 100.011375,12.995677 41.522961,13.006619 
C25.444475,13.009627 15.005924,23.465946 15.005089,39.551918 
C14.999542,146.364090 15.002211,253.176270 15.002203,359.988434 
C15.002202,361.607483 15.002202,363.226532 15.002202,366.193420 
C21.762453,365.007812 28.200909,364.870483 33.876518,362.719879 
C66.937729,350.192474 99.843994,337.253876 132.750488,324.321594 
C145.562973,319.286285 158.341965,314.138062 170.940933,308.597229 
C178.847809,305.119934 183.245209,299.023254 183.224884,289.819885 
C183.103073,234.664398 183.147934,179.508514 183.185013,124.352798 
C183.190186,116.668633 180.262314,110.336922 174.403641,105.601448 
C170.551208,102.487572 166.329102,99.713776 161.956955,97.378510 
C122.699432,76.410118 83.369286,55.577709 44.069298,34.688766 
C40.441101,32.760288 36.873947,30.716961 33.278454,28.726961 
C36.251862,27.073637 39.126167,26.256880 42.008591,26.227129 
C55.504478,26.087833 69.002602,26.165287 83.499939,26.165304 
M1025.903931,171.580109 
C1016.117310,118.989151 1006.330688,66.398186 996.534729,13.757463 
C990.503479,13.757463 984.916443,13.757463 979.153503,13.757463 
C968.004211,73.784180 956.898010,133.579102 945.624634,194.273758 
C944.958984,192.154800 944.488098,190.927704 944.187744,189.660202 
C935.451416,152.786011 926.738708,115.906258 918.007690,79.030830 
C913.144409,58.490711 908.302002,37.945263 903.280273,17.443813 
C902.914734,15.951420 901.300415,13.854899 900.028015,13.668528 
C896.483521,13.149333 892.813293,13.488164 888.790955,13.488164 
C889.115845,15.273212 889.283752,16.552000 889.584900,17.798626 
C898.170532,53.337791 906.752441,88.877884 915.371887,124.408859 
C922.228210,152.671997 929.096863,180.932358 936.122070,209.153610 
C936.476807,210.578659 938.296692,212.580383 939.557007,212.674728 
C944.843628,213.070465 950.177002,212.841766 955.830322,212.841766 
C966.361206,155.558395 976.847717,98.516197 987.334290,41.473995 
C987.672913,41.461445 988.011597,41.448895 988.350281,41.436340 
C998.856384,98.548065 1009.362549,155.659790 1019.857239,212.709518 
C1026.473267,212.709518 1032.452026,212.709518 1038.836426,212.709518 
C1054.721436,146.518295 1070.598389,80.360970 1086.577637,13.777230 
C1081.508423,13.777230 1077.095947,13.777230 1072.340820,13.777230 
C1058.460938,73.458328 1044.585693,133.119400 1030.710449,192.780472 
C1030.435791,192.804718 1030.161133,192.828949 1029.886475,192.853195 
C1028.592041,186.047241 1027.297607,179.241302 1025.903931,171.580109 
M839.226929,132.885132 
C849.604614,126.892746 855.813965,117.817459 858.959839,106.411148 
C863.406372,90.288872 864.441040,73.964912 861.875366,57.447590 
C857.821411,31.349525 841.432373,15.242649 815.109375,13.994143 
C790.503723,12.827091 765.805237,13.617238 741.147522,13.564779 
C740.846924,13.564139 740.545776,13.813033 740.181091,13.973497 
C740.181091,80.157295 740.181091,146.364120 740.181091,212.423218 
C744.597656,212.423218 748.640503,212.423218 753.054504,212.423218 
C753.054504,188.089020 753.054504,164.173279 753.054504,140.005249 
C755.242065,140.005249 757.053955,140.009613 758.865845,140.004547 
C777.861816,139.951584 796.866577,140.198654 815.847717,139.640533 
C820.874207,139.492722 822.568115,141.016098 824.197021,145.339783 
C832.681458,167.859818 841.512756,190.249161 850.227844,212.695526 
C854.573059,212.695526 858.708191,212.695526 863.605408,212.695526 
C853.812683,186.774918 844.161682,161.229401 834.453918,135.533569 
C835.977234,134.656525 837.255798,133.920410 839.226929,132.885132 
M381.127899,54.400940 
C373.977631,31.201283 355.776093,15.669895 331.236481,14.194458 
C309.486023,12.886718 287.621613,13.473221 265.807159,13.252942 
C265.553528,13.250380 265.295868,13.640889 265.173035,13.740315 
C265.173035,80.090469 265.173035,146.326630 265.173035,212.300140 
C287.820770,212.300140 310.140259,212.972778 332.402954,212.135513 
C362.459961,211.005081 383.663849,194.974030 384.170685,159.292419 
C384.563477,131.638275 384.622101,103.974411 384.358826,76.319344 
C384.291565,69.257706 382.439697,62.213062 381.127899,54.400940 
M699.008423,94.500000 
C698.679443,81.529709 699.137024,68.480545 697.846130,55.606709 
C695.644226,33.647999 681.222473,16.709517 660.142029,11.618109 
C651.076355,9.428522 641.307556,8.807820 631.966064,9.345972 
C599.752380,11.201761 579.833069,32.243061 579.544556,64.366020 
C579.248352,97.346992 579.315430,130.333786 579.534424,163.315979 
C579.695312,187.538223 593.410461,205.381287 616.640076,212.416458 
C627.458801,215.692963 638.482483,216.767563 649.695679,215.119705 
C678.877441,210.831238 699.254028,194.078003 699.035095,160.467590 
C698.894043,138.812592 699.008484,117.155922 699.008423,94.500000 
M445.391998,19.851727 
C429.462128,31.203466 424.467010,47.782696 424.257477,66.100960 
C423.891479,98.095779 424.023499,130.098526 424.199249,162.096573 
C424.325623,185.101929 436.635742,203.408722 456.560699,210.812546 
C471.294098,216.287231 486.410400,217.413528 501.725739,213.878403 
C513.460754,211.169693 523.963379,205.936142 532.104858,196.800827 
C540.897644,186.934784 543.698242,174.837967 543.780823,162.098709 
C543.976440,131.934250 543.881042,101.767570 543.807556,71.602028 
C543.795471,66.617859 543.633728,61.589565 542.966675,56.659397 
C540.470581,38.211391 532.196045,22.904638 514.772705,15.277921 
C491.520233,5.099558 468.173401,6.066702 445.391998,19.851727 
M1136.822388,23.282330 
C1116.109131,86.294472 1095.395752,149.306625 1074.509888,212.843521 
C1076.541504,212.843521 1077.845703,212.837158 1079.149902,212.844543 
C1088.203247,212.895737 1088.215698,212.900742 1090.979126,204.487717 
C1098.020874,183.048187 1105.050659,161.604660 1112.050293,140.269394 
C1134.880859,140.269394 1157.465454,140.269394 1180.119019,140.269394 
C1180.474243,141.250198 1180.769531,142.016220 1181.030640,142.793808 
C1188.372925,164.669189 1195.668457,186.560608 1203.136108,208.393112 
C1203.715332,210.086365 1205.325928,212.399597 1206.755737,212.638596 
C1210.391846,213.246414 1214.197510,212.840744 1218.344116,212.840744 
C1217.889160,211.221695 1217.665039,210.277893 1217.360352,209.360931 
C1196.754395,147.372772 1176.148315,85.384674 1155.523926,23.402672 
C1152.018188,12.866707 1151.980713,12.879162 1140.052612,13.749751 
C1139.071777,16.582623 1138.044922,19.547977 1136.822388,23.282330 
M216.998428,252.531830 
C216.998291,249.700500 217.155960,246.856430 216.928421,244.043488 
C216.822021,242.728043 216.337280,240.826584 215.398621,240.294952 
C209.219559,236.795212 202.869980,233.596512 195.845551,229.916992 
C195.845551,232.291412 195.845551,233.883942 195.845551,235.476486 
C195.845505,254.796677 195.860367,274.116882 195.832764,293.437012 
C195.827301,297.262604 195.633194,301.087463 195.571320,304.913422 
C195.476471,310.779785 192.473694,314.550995 187.178436,316.700012 
C185.177231,317.512146 183.183685,318.344727 181.167709,319.118622 
C151.697601,330.431488 122.209442,341.697479 92.760521,353.065155 
C83.467178,356.652496 74.276855,360.506714 63.749832,364.759766 
C109.385803,364.759766 153.469482,364.759766 197.998322,364.759766 
C197.998322,363.071991 198.163162,361.594849 197.971252,360.165619 
C196.822266,351.608582 199.801575,345.768280 208.276779,342.674225 
C214.882416,340.262695 217.102249,334.866180 217.058929,327.969910 
C216.903046,303.154480 216.998154,278.337402 216.998428,252.531830 
M1334.002808,106.500000 
C1334.002808,75.593315 1334.002808,44.686626 1334.002808,13.805242 
C1329.265747,13.805242 1325.187500,13.805242 1320.827637,13.805242 
C1320.827637,15.439514 1320.827759,16.758528 1320.827759,18.077539 
C1320.827881,42.571232 1320.569824,67.068642 1320.922974,91.557243 
C1321.140381,106.640823 1314.057861,116.775841 1301.380615,123.691208 
C1289.503418,130.170059 1276.592285,131.163010 1263.513062,129.782074 
C1237.354004,127.020187 1219.775513,111.687088 1220.706177,82.782661 
C1221.386841,61.643353 1220.839600,40.464516 1220.839600,19.303297 
C1220.839600,17.526554 1220.839722,15.749811 1220.839722,13.793688 
C1216.257812,13.793688 1212.188721,13.793688 1208.079346,13.793688 
C1208.079346,40.405895 1207.443970,66.735130 1208.315430,93.014412 
C1208.845703,109.004189 1216.039062,122.547386 1229.843140,131.661438 
C1245.596802,142.062805 1263.239990,144.368286 1281.515991,142.431351 
C1295.852661,140.911911 1309.466553,136.978241 1320.615479,125.989021 
C1320.615479,143.002823 1320.805298,159.110550 1320.557373,175.211517 
C1320.346313,188.919617 1315.066162,199.742081 1301.664185,205.331833 
C1301.429810,205.429565 1301.341431,205.877106 1301.025879,206.450363 
C1302.011108,208.993210 1303.068848,211.723389 1304.159302,214.537842 
C1316.739258,212.483627 1325.558105,205.821106 1329.627441,194.334595 
C1332.135132,187.255814 1333.622192,179.483780 1333.812012,171.978561 
C1334.355591,150.497849 1334.003052,128.994461 1334.002808,106.500000 
z"
    />
    <path
      fill="#FF9502"
      opacity="1.000000"
      stroke="none"
      d="
M265.010315,349.433899 
C264.932526,326.659027 264.773041,304.326324 264.904724,281.995361 
C264.969360,271.030853 272.659790,263.859253 283.825989,263.854706 
C332.992554,263.834656 382.159149,263.846008 431.325714,263.845978 
C725.491821,263.845856 1019.658020,263.893555 1313.823975,263.679749 
C1326.258789,263.670715 1334.998291,271.413422 1334.884644,284.654541 
C1334.714722,304.486389 1334.889526,324.320923 1334.823364,344.153992 
C1334.777466,357.863068 1327.470337,365.146301 1313.852905,365.146606 
C971.020203,365.154663 628.187500,365.154572 285.354767,365.145813 
C273.991852,365.145508 267.964294,360.561798 265.010315,349.433899 
M1217.763672,310.409576 
C1212.547852,309.611572 1207.326050,308.122864 1202.117676,308.168854 
C1193.108765,308.248383 1188.072998,313.810944 1187.852783,322.722351 
C1187.770630,326.045807 1187.768188,329.375061 1187.858521,332.698181 
C1188.081177,340.887604 1191.269287,345.432739 1198.920654,347.988922 
C1209.787109,351.619110 1220.803467,350.145569 1232.156128,349.035645 
C1232.156128,337.633240 1232.304565,326.678619 1232.118774,315.729736 
C1231.884766,301.948425 1222.213135,294.614044 1208.175781,297.533752 
C1207.981445,299.559906 1207.780884,301.652100 1207.589844,303.644348 
C1211.648682,305.856781 1217.787109,303.349121 1218.883911,310.140228 
C1218.761230,310.292542 1218.638428,310.444885 1217.763672,310.409576 
M548.096802,330.539337 
C554.834595,330.167786 561.572388,329.796234 568.502563,329.414062 
C568.502563,323.075500 569.191711,317.311432 568.265320,311.819916 
C567.625305,308.026245 565.522217,303.781769 562.728760,301.184418 
C555.401428,294.371399 539.177246,294.871246 532.325256,302.152863 
C529.606506,305.042084 527.652222,309.537415 527.224182,313.503784 
C526.424500,320.914429 526.897156,328.474518 527.023376,335.969330 
C527.133789,342.522308 530.376404,347.120636 536.710938,348.959412 
C546.558167,351.817780 556.032654,350.339142 565.567444,345.584747 
C564.020874,342.334900 562.632263,339.416901 561.319153,336.657593 
C556.510315,337.850342 552.254578,339.432465 547.884705,339.867065 
C541.237976,340.528137 539.408081,338.140289 540.354980,331.047821 
C542.587097,330.894073 544.882263,330.736023 548.096802,330.539337 
M822.776855,310.229767 
C820.087952,300.264404 810.505676,295.061920 799.177917,297.561523 
C798.995605,299.605988 798.808289,301.706329 798.622559,303.789001 
C803.019836,305.639801 809.246887,303.755646 810.681213,310.125427 
C804.672058,309.395447 798.975098,308.120850 793.284058,308.146759 
C784.453491,308.186920 779.128357,313.926483 778.866638,322.657440 
C778.761902,326.151123 778.762207,329.655273 778.899658,333.147400 
C779.204407,340.891296 782.529785,345.501923 789.961426,347.986755 
C800.830627,351.620972 811.848022,350.170166 822.895386,349.042389 
C822.895386,336.031799 822.895386,323.563965 822.776855,310.229767 
M1008.135315,311.202728 
C1008.034790,317.846497 1007.790283,324.491913 1007.867065,331.133606 
C1007.986755,341.481232 1015.004822,348.913696 1025.388184,349.975494 
C1038.880493,351.355225 1048.013550,346.292328 1050.008179,335.403076 
C1051.101318,329.434814 1050.807129,323.179169 1050.815674,317.052643 
C1050.831421,305.709198 1044.806396,298.505432 1034.241211,296.857758 
C1020.920776,294.780396 1012.046204,299.414032 1008.135315,311.202728 
M688.991760,338.479645 
C688.991760,341.924316 688.991760,345.368958 688.991760,348.865112 
C693.297729,348.865112 697.028442,348.865112 701.167053,348.865112 
C701.167053,346.947784 701.167053,345.315735 701.167053,343.683685 
C701.167053,333.369324 701.079834,323.053345 701.267090,312.742371 
C701.293335,311.298004 702.331665,309.029480 703.468567,308.581604 
C712.273926,305.112488 719.626953,309.283356 719.119751,318.389160 
C718.558655,328.462463 718.991516,338.591125 718.991516,348.861023 
C723.359924,348.861023 727.239746,348.861023 731.584351,348.861023 
C731.584351,337.112762 731.653870,325.663879 731.563049,314.216309 
C731.485962,304.495178 726.447998,298.107086 716.828308,297.284515 
C707.692932,296.503357 698.438354,297.116425 688.991760,297.116425 
C688.991760,311.219116 688.991760,324.351471 688.991760,338.479645 
M1097.992798,336.490540 
C1097.992798,340.598175 1097.992798,344.705811 1097.992798,348.861298 
C1102.302002,348.861298 1106.032471,348.861298 1110.166748,348.861298 
C1110.166748,336.786316 1110.082153,325.009613 1110.265137,313.237091 
C1110.290405,311.617676 1111.260010,309.025513 1112.443726,308.567719 
C1121.894043,304.912903 1128.627075,309.889862 1128.130615,318.390778 
C1127.542358,328.459961 1127.992310,338.589752 1127.992310,348.861633 
C1132.358521,348.861633 1136.237793,348.861633 1140.581787,348.861633 
C1140.581787,337.115906 1140.650879,325.668304 1140.560425,314.221985 
C1140.484375,304.591095 1135.418945,298.146973 1125.815674,297.293976 
C1116.688599,296.483307 1107.432861,297.119476 1097.992798,297.119476 
C1097.992798,310.561371 1097.992798,323.026947 1097.992798,336.490540 
M413.063080,310.977509 
C411.591583,303.032318 406.744415,297.887451 398.271667,297.252075 
C389.280060,296.577759 380.198669,297.100555 371.155334,297.100555 
C371.155334,314.994812 371.155334,331.886536 371.155334,348.849121 
C375.200470,348.849121 378.924469,348.849121 383.002228,348.849121 
C383.002228,336.591431 383.115570,324.637939 382.921875,312.689392 
C382.873962,309.734406 383.979370,308.599243 386.610352,307.940125 
C395.640991,305.677765 400.817993,309.613586 400.836426,318.944153 
C400.856018,328.873474 400.840851,338.802887 400.840851,348.736023 
C405.285614,348.736023 409.140137,348.736023 413.144531,348.736023 
C413.144531,336.169678 413.144531,324.025757 413.063080,310.977509 
M466.578033,276.989258 
C464.840363,276.989258 463.102692,276.989258 461.152252,276.989258 
C461.152252,297.075348 461.112793,316.351440 461.208435,335.626923 
C461.218903,337.739838 461.748108,339.943359 462.482239,341.941132 
C464.341644,347.001282 470.231934,350.509979 476.616943,350.304077 
C481.305603,350.152893 479.933411,345.312500 481.832611,342.963745 
C481.954651,342.812836 481.723480,342.376312 481.656555,342.072632 
C478.623810,340.891327 474.582581,339.005585 474.418518,336.827789 
C473.701691,327.312958 474.110107,317.713409 474.110107,308.190369 
C483.480865,307.746490 484.049072,307.128296 484.353973,297.338257 
C481.068268,297.338257 477.791168,297.338257 473.970215,297.338257 
C473.970215,290.421295 473.970215,283.864655 473.970215,276.989288 
C471.462219,276.989288 469.493439,276.989288 466.578033,276.989258 
M881.183777,277.021027 
C877.572327,277.021027 873.960938,277.021027 870.061035,277.021027 
C870.061035,297.499878 870.005920,317.459137 870.144165,337.416992 
C870.158447,339.471954 870.998535,341.702667 872.022766,343.534698 
C874.970276,348.807343 880.313904,350.587463 885.625916,350.030884 
C887.559021,349.828369 889.033264,345.223450 890.700195,342.616455 
C890.749512,342.539398 890.611023,342.342285 890.560059,342.201141 
C887.568909,341.008698 883.458618,339.168610 883.297241,337.030457 
C882.576904,327.489227 882.984314,317.862885 882.984314,307.927795 
C885.964966,307.927795 888.409546,307.927795 891.135498,307.927795 
C891.957275,304.500244 892.714539,301.341705 893.586121,297.706085 
C889.780640,297.502686 886.553650,297.330170 882.855469,297.132477 
C882.855469,295.052216 882.923218,293.254547 882.842407,291.463562 
C882.628052,286.710693 882.349426,281.960724 881.183777,277.021027 
M641.743774,307.861725 
C648.865845,307.590363 644.618225,301.219757 647.570007,298.711243 
C647.333801,298.265533 647.097656,297.819794 646.861450,297.374084 
C636.433533,297.374084 626.005676,297.374084 615.434387,297.374084 
C615.434387,314.936859 615.434387,331.834534 615.434387,348.854858 
C619.634705,348.854858 623.531799,348.854858 627.866150,348.854858 
C627.866150,338.580017 627.838196,328.621613 627.874573,318.663391 
C627.906128,310.035217 632.114868,306.534241 641.743774,307.861725 
M1293.129028,347.328918 
C1293.129028,323.961914 1293.129028,300.594879 1293.129028,277.315002 
C1288.641479,277.315002 1284.895142,277.315002 1281.141479,277.315002 
C1281.141479,301.332367 1281.141479,325.035034 1281.141479,349.496674 
C1285.327637,349.071106 1289.161743,348.681335 1293.129028,347.328918 
M940.978699,303.637695 
C940.978699,318.715881 940.978699,333.794098 940.978699,348.792999 
C945.490234,348.792999 949.368347,348.792999 953.212646,348.792999 
C953.212646,331.535370 953.212646,314.670624 953.212646,297.804169 
C948.939819,297.804169 945.072449,297.804169 940.978699,297.804169 
C940.978699,299.706909 940.978699,301.195068 940.978699,303.637695 
M318.021240,308.525757 
C318.021240,304.936920 318.021240,301.348083 318.021240,297.828918 
C313.468689,297.828918 309.597412,297.828918 305.776550,297.828918 
C305.776550,315.133911 305.776550,331.994995 305.776550,348.785339 
C310.049896,348.785339 313.927917,348.785339 318.021240,348.785339 
C318.021240,335.431854 318.021240,322.474304 318.021240,308.525757 
M954.089417,282.362854 
C953.954407,278.038696 951.306519,275.677795 947.428772,276.207947 
C945.126648,276.522705 942.255859,278.600616 941.199280,280.678345 
C939.716003,283.595093 942.007202,285.934357 944.833374,287.244751 
C948.509338,288.949158 951.207825,287.445007 953.398315,284.493378 
C953.683350,284.109375 953.792114,283.594574 954.089417,282.362854 
M305.402710,280.845917 
C305.763733,282.240112 305.798889,283.848389 306.547577,284.987854 
C308.692902,288.252747 313.182831,288.884308 316.171143,286.457397 
C318.805756,284.317657 319.847961,281.734222 317.551208,278.687866 
C315.200195,275.569519 311.011322,275.030792 307.847504,277.505493 
C306.973114,278.189453 306.411469,279.273285 305.402710,280.845917 
z"
    />
    <path
      fill="#0D0D0D"
      opacity="1.000000"
      stroke="none"
      d="
M82.999939,26.165304 
C69.002602,26.165287 55.504478,26.087833 42.008591,26.227129 
C39.126167,26.256880 36.251862,27.073637 33.278454,28.726961 
C36.873947,30.716961 40.441101,32.760288 44.069298,34.688766 
C83.369286,55.577709 122.699432,76.410118 161.956955,97.378510 
C166.329102,99.713776 170.551208,102.487572 174.403641,105.601448 
C180.262314,110.336922 183.190186,116.668633 183.185013,124.352798 
C183.147934,179.508514 183.103073,234.664398 183.224884,289.819885 
C183.245209,299.023254 178.847809,305.119934 170.940933,308.597229 
C158.341965,314.138062 145.562973,319.286285 132.750488,324.321594 
C99.843994,337.253876 66.937729,350.192474 33.876518,362.719879 
C28.200909,364.870483 21.762453,365.007812 15.002202,366.193420 
C15.002202,363.226532 15.002202,361.607483 15.002203,359.988434 
C15.002211,253.176270 14.999542,146.364090 15.005089,39.551918 
C15.005924,23.465946 25.444475,13.009627 41.522961,13.006619 
C100.011375,12.995677 158.499786,12.988219 216.988205,13.012027 
C232.183151,13.018212 242.968048,23.544636 242.974197,38.609184 
C243.018219,146.587753 242.997345,254.566376 242.993042,362.544983 
C242.993011,363.196320 242.935669,363.847626 242.889542,364.829651 
C231.434219,364.829651 220.062683,364.829651 208.427704,364.829651 
C208.427704,360.693604 208.427704,356.618408 208.427704,352.111267 
C215.435593,352.111267 222.358002,352.111267 229.833572,352.111267 
C229.833572,350.084869 229.833572,348.478821 229.833588,346.872803 
C229.834061,244.893005 229.835831,142.913223 229.833176,40.933426 
C229.832901,30.459703 225.502975,26.166147 214.972504,26.165827 
C171.148315,26.164486 127.324127,26.165289 82.999939,26.165304 
z"
    />
    <path
      fill="#111111"
      opacity="1.000000"
      stroke="none"
      d="
M1025.953613,172.007721 
C1027.297607,179.241302 1028.592041,186.047241 1029.886475,192.853195 
C1030.161133,192.828949 1030.435791,192.804718 1030.710449,192.780472 
C1044.585693,133.119400 1058.460938,73.458328 1072.340820,13.777230 
C1077.095947,13.777230 1081.508423,13.777230 1086.577637,13.777230 
C1070.598389,80.360970 1054.721436,146.518295 1038.836426,212.709518 
C1032.452026,212.709518 1026.473267,212.709518 1019.857239,212.709518 
C1009.362549,155.659790 998.856384,98.548065 988.350281,41.436340 
C988.011597,41.448895 987.672913,41.461445 987.334290,41.473995 
C976.847717,98.516197 966.361206,155.558395 955.830322,212.841766 
C950.177002,212.841766 944.843628,213.070465 939.557007,212.674728 
C938.296692,212.580383 936.476807,210.578659 936.122070,209.153610 
C929.096863,180.932358 922.228210,152.671997 915.371887,124.408859 
C906.752441,88.877884 898.170532,53.337791 889.584900,17.798626 
C889.283752,16.552000 889.115845,15.273212 888.790955,13.488164 
C892.813293,13.488164 896.483521,13.149333 900.028015,13.668528 
C901.300415,13.854899 902.914734,15.951420 903.280273,17.443813 
C908.302002,37.945263 913.144409,58.490711 918.007690,79.030830 
C926.738708,115.906258 935.451416,152.786011 944.187744,189.660202 
C944.488098,190.927704 944.958984,192.154800 945.624634,194.273758 
C956.898010,133.579102 968.004211,73.784180 979.153503,13.757463 
C984.916443,13.757463 990.503479,13.757463 996.534729,13.757463 
C1006.330688,66.398186 1016.117310,118.989151 1025.953613,172.007721 
z"
    />
    <path
      fill="#111111"
      opacity="1.000000"
      stroke="none"
      d="
M838.880615,133.034698 
C837.255798,133.920410 835.977234,134.656525 834.453918,135.533569 
C844.161682,161.229401 853.812683,186.774918 863.605408,212.695526 
C858.708191,212.695526 854.573059,212.695526 850.227844,212.695526 
C841.512756,190.249161 832.681458,167.859818 824.197021,145.339783 
C822.568115,141.016098 820.874207,139.492722 815.847717,139.640533 
C796.866577,140.198654 777.861816,139.951584 758.865845,140.004547 
C757.053955,140.009613 755.242065,140.005249 753.054504,140.005249 
C753.054504,164.173279 753.054504,188.089020 753.054504,212.423218 
C748.640503,212.423218 744.597656,212.423218 740.181091,212.423218 
C740.181091,146.364120 740.181091,80.157295 740.181091,13.973497 
C740.545776,13.813033 740.846924,13.564139 741.147522,13.564779 
C765.805237,13.617238 790.503723,12.827091 815.109375,13.994143 
C841.432373,15.242649 857.821411,31.349525 861.875366,57.447590 
C864.441040,73.964912 863.406372,90.288872 858.959839,106.411148 
C855.813965,117.817459 849.604614,126.892746 838.880615,133.034698 
M752.844971,42.501389 
C752.844971,70.601730 752.844971,98.702065 752.844971,126.817108 
C753.804199,126.984619 754.271301,127.138252 754.738098,127.137306 
C774.396973,127.097557 794.063110,127.353981 813.712341,126.891708 
C828.773376,126.537369 839.623535,119.156593 845.165222,104.909752 
C851.129639,89.575836 851.711609,73.642052 848.464966,57.752880 
C844.122253,36.498955 830.573547,26.010067 808.870667,26.007568 
C791.709290,26.005594 774.547852,26.001949 757.386475,26.016832 
C755.940796,26.018085 754.495178,26.154318 752.844971,26.238247 
C752.844971,31.547361 752.844971,36.524986 752.844971,42.501389 
z"
    />
    <path
      fill="#121212"
      opacity="1.000000"
      stroke="none"
      d="
M381.267822,54.780777 
C382.439697,62.213062 384.291565,69.257706 384.358826,76.319344 
C384.622101,103.974411 384.563477,131.638275 384.170685,159.292419 
C383.663849,194.974030 362.459961,211.005081 332.402954,212.135513 
C310.140259,212.972778 287.820770,212.300140 265.173035,212.300140 
C265.173035,146.326630 265.173035,80.090469 265.173035,13.740315 
C265.295868,13.640889 265.553528,13.250380 265.807159,13.252942 
C287.621613,13.473221 309.486023,12.886718 331.236481,14.194458 
C355.776093,15.669895 373.977631,31.201283 381.267822,54.780777 
M371.151550,121.500053 
C371.151703,105.515091 371.211243,89.529854 371.138580,73.545219 
C371.019196,47.279240 353.866302,27.944191 327.733734,26.038517 
C312.329285,24.915173 296.790466,25.628107 281.311920,25.540468 
C280.230743,25.534346 279.148346,25.738279 278.089111,25.842314 
C278.089111,84.275406 278.089111,142.319351 278.089111,200.344849 
C294.925568,200.344849 311.546326,200.888611 328.103943,200.102402 
C335.732849,199.740143 343.412231,197.299576 350.757202,194.862457 
C360.803711,191.528931 366.965515,184.074112 369.231842,173.742859 
C370.257294,169.068268 370.968781,164.238510 371.066833,159.463623 
C371.319794,147.147049 371.151550,134.821823 371.151550,121.500053 
z"
    />
    <path
      fill="#121212"
      opacity="1.000000"
      stroke="none"
      d="
M699.008423,95.000000 
C699.008484,117.155922 698.894043,138.812592 699.035095,160.467590 
C699.254028,194.078003 678.877441,210.831238 649.695679,215.119705 
C638.482483,216.767563 627.458801,215.692963 616.640076,212.416458 
C593.410461,205.381287 579.695312,187.538223 579.534424,163.315979 
C579.315430,130.333786 579.248352,97.346992 579.544556,64.366020 
C579.833069,32.243061 599.752380,11.201761 631.966064,9.345972 
C641.307556,8.807820 651.076355,9.428522 660.142029,11.618109 
C681.222473,16.709517 695.644226,33.647999 697.846130,55.606709 
C699.137024,68.480545 698.679443,81.529709 699.008423,95.000000 
M648.097229,21.984201 
C644.112488,21.830940 640.124084,21.481764 636.143616,21.556099 
C609.619385,22.051441 592.727905,38.537487 592.572266,64.491219 
C592.377625,96.960945 592.432739,129.433090 592.561096,161.903488 
C592.634827,180.564117 602.111267,194.315018 619.856873,199.856674 
C627.457825,202.230316 635.892029,203.212036 643.864990,202.874207 
C666.165833,201.929260 686.663025,189.796524 686.242798,159.037003 
C685.824280,128.403519 686.160767,97.759842 686.148132,67.120644 
C686.137390,41.215668 674.403503,26.990650 648.097229,21.984201 
z"
    />
    <path
      fill="#121212"
      opacity="1.000000"
      stroke="none"
      d="
M445.668610,19.620361 
C468.173401,6.066702 491.520233,5.099558 514.772705,15.277921 
C532.196045,22.904638 540.470581,38.211391 542.966675,56.659397 
C543.633728,61.589565 543.795471,66.617859 543.807556,71.602028 
C543.881042,101.767570 543.976440,131.934250 543.780823,162.098709 
C543.698242,174.837967 540.897644,186.934784 532.104858,196.800827 
C523.963379,205.936142 513.460754,211.169693 501.725739,213.878403 
C486.410400,217.413528 471.294098,216.287231 456.560699,210.812546 
C436.635742,203.408722 424.325623,185.101929 424.199249,162.096573 
C424.023499,130.098526 423.891479,98.095779 424.257477,66.100960 
C424.467010,47.782696 429.462128,31.203466 445.668610,19.620361 
M449.184723,33.678570 
C440.191650,42.404648 437.329773,53.580994 437.250732,65.505608 
C437.039795,97.320908 437.039734,129.139343 437.227631,160.954926 
C437.351013,181.846237 448.202850,196.050568 468.533234,201.089447 
C475.817413,202.894821 483.727753,203.275925 491.255219,202.793228 
C515.021851,201.269196 530.749878,185.426102 530.923828,162.958328 
C531.181763,129.644409 531.212769,96.324837 530.885010,63.012150 
C530.747009,48.993645 525.351501,37.188915 513.272583,28.994675 
C497.691193,18.424372 464.449341,18.159969 449.184723,33.678570 
z"
    />
    <path
      fill="#121212"
      opacity="1.000000"
      stroke="none"
      d="
M1136.920288,22.897831 
C1138.044922,19.547977 1139.071777,16.582623 1140.052612,13.749751 
C1151.980713,12.879162 1152.018188,12.866707 1155.523926,23.402672 
C1176.148315,85.384674 1196.754395,147.372772 1217.360352,209.360931 
C1217.665039,210.277893 1217.889160,211.221695 1218.344116,212.840744 
C1214.197510,212.840744 1210.391846,213.246414 1206.755737,212.638596 
C1205.325928,212.399597 1203.715332,210.086365 1203.136108,208.393112 
C1195.668457,186.560608 1188.372925,164.669189 1181.030640,142.793808 
C1180.769531,142.016220 1180.474243,141.250198 1180.119019,140.269394 
C1157.465454,140.269394 1134.880859,140.269394 1112.050293,140.269394 
C1105.050659,161.604660 1098.020874,183.048187 1090.979126,204.487717 
C1088.215698,212.900742 1088.203247,212.895737 1079.149902,212.844543 
C1077.845703,212.837158 1076.541504,212.843521 1074.509888,212.843521 
C1095.395752,149.306625 1116.109131,86.294472 1136.920288,22.897831 
M1156.733276,66.861420 
C1153.341919,56.684780 1149.950562,46.508141 1146.107056,34.974598 
C1135.943359,66.335007 1126.170288,96.490181 1116.296509,126.956116 
C1136.696045,126.956116 1156.303345,126.956116 1176.519897,126.956116 
C1169.914429,106.942917 1163.427979,87.289932 1156.733276,66.861420 
z"
    />
    <path
      fill="#FF9502"
      opacity="1.000000"
      stroke="none"
      d="
M216.998428,253.026398 
C216.998154,278.337402 216.903046,303.154480 217.058929,327.969910 
C217.102249,334.866180 214.882416,340.262695 208.276779,342.674225 
C199.801575,345.768280 196.822266,351.608582 197.971252,360.165619 
C198.163162,361.594849 197.998322,363.071991 197.998322,364.759766 
C153.469482,364.759766 109.385803,364.759766 63.749832,364.759766 
C74.276855,360.506714 83.467178,356.652496 92.760521,353.065155 
C122.209442,341.697479 151.697601,330.431488 181.167709,319.118622 
C183.183685,318.344727 185.177231,317.512146 187.178436,316.700012 
C192.473694,314.550995 195.476471,310.779785 195.571320,304.913422 
C195.633194,301.087463 195.827301,297.262604 195.832764,293.437012 
C195.860367,274.116882 195.845505,254.796677 195.845551,235.476486 
C195.845551,233.883942 195.845551,232.291412 195.845551,229.916992 
C202.869980,233.596512 209.219559,236.795212 215.398621,240.294952 
C216.337280,240.826584 216.822021,242.728043 216.928421,244.043488 
C217.155960,246.856430 216.998291,249.700500 216.998428,253.026398 
z"
    />
    <path
      fill="#101010"
      opacity="1.000000"
      stroke="none"
      d="
M1334.002808,107.000000 
C1334.003052,128.994461 1334.355591,150.497849 1333.812012,171.978561 
C1333.622192,179.483780 1332.135132,187.255814 1329.627441,194.334595 
C1325.558105,205.821106 1316.739258,212.483627 1304.159302,214.537842 
C1303.068848,211.723389 1302.011108,208.993210 1301.025879,206.450363 
C1301.341431,205.877106 1301.429810,205.429565 1301.664185,205.331833 
C1315.066162,199.742081 1320.346313,188.919617 1320.557373,175.211517 
C1320.805298,159.110550 1320.615479,143.002823 1320.615479,125.989021 
C1309.466553,136.978241 1295.852661,140.911911 1281.515991,142.431351 
C1263.239990,144.368286 1245.596802,142.062805 1229.843140,131.661438 
C1216.039062,122.547386 1208.845703,109.004189 1208.315430,93.014412 
C1207.443970,66.735130 1208.079346,40.405895 1208.079346,13.793688 
C1212.188721,13.793688 1216.257812,13.793688 1220.839722,13.793688 
C1220.839722,15.749811 1220.839600,17.526554 1220.839600,19.303297 
C1220.839600,40.464516 1221.386841,61.643353 1220.706177,82.782661 
C1219.775513,111.687088 1237.354004,127.020187 1263.513062,129.782074 
C1276.592285,131.163010 1289.503418,130.170059 1301.380615,123.691208 
C1314.057861,116.775841 1321.140381,106.640823 1320.922974,91.557243 
C1320.569824,67.068642 1320.827881,42.571232 1320.827759,18.077539 
C1320.827759,16.758528 1320.827637,15.439514 1320.827637,13.805242 
C1325.187500,13.805242 1329.265747,13.805242 1334.002808,13.805242 
C1334.002808,44.686626 1334.002808,75.593315 1334.002808,107.000000 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M547.637085,330.558655 
C544.882263,330.736023 542.587097,330.894073 540.354980,331.047821 
C539.408081,338.140289 541.237976,340.528137 547.884705,339.867065 
C552.254578,339.432465 556.510315,337.850342 561.319153,336.657593 
C562.632263,339.416901 564.020874,342.334900 565.567444,345.584747 
C556.032654,350.339142 546.558167,351.817780 536.710938,348.959412 
C530.376404,347.120636 527.133789,342.522308 527.023376,335.969330 
C526.897156,328.474518 526.424500,320.914429 527.224182,313.503784 
C527.652222,309.537415 529.606506,305.042084 532.325256,302.152863 
C539.177246,294.871246 555.401428,294.371399 562.728760,301.184418 
C565.522217,303.781769 567.625305,308.026245 568.265320,311.819916 
C569.191711,317.311432 568.502563,323.075500 568.502563,329.414062 
C561.572388,329.796234 554.834595,330.167786 547.637085,330.558655 
M555.481567,311.517395 
C552.265625,307.565704 547.256226,306.267426 543.255737,308.559509 
C538.169067,311.473846 539.986877,316.068695 540.237793,320.592468 
C545.681763,320.263641 550.726685,319.958923 555.768616,319.654419 
C555.768616,316.895538 555.768616,314.595215 555.481567,311.517395 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1219.290894,309.717377 
C1217.787109,303.349121 1211.648682,305.856781 1207.589844,303.644348 
C1207.780884,301.652100 1207.981445,299.559906 1208.175781,297.533752 
C1222.213135,294.614044 1231.884766,301.948425 1232.118774,315.729736 
C1232.304565,326.678619 1232.156128,337.633240 1232.156128,349.035645 
C1220.803467,350.145569 1209.787109,351.619110 1198.920654,347.988922 
C1191.269287,345.432739 1188.081177,340.887604 1187.858521,332.698181 
C1187.768188,329.375061 1187.770630,326.045807 1187.852783,322.722351 
C1188.072998,313.810944 1193.108765,308.248383 1202.117676,308.168854 
C1207.326050,308.122864 1212.547852,309.611572 1218.343384,310.600464 
C1219.045654,310.433380 1219.168213,310.075378 1219.290894,309.717377 
M1200.822388,333.357147 
C1203.748535,339.503021 1211.404541,341.741302 1219.863892,338.716156 
C1219.863892,335.702545 1219.864258,332.572174 1219.863892,329.441772 
C1219.862915,322.209961 1219.654663,321.928986 1212.641479,320.139801 
C1211.522583,319.854340 1210.376465,319.661133 1209.233887,319.484589 
C1202.771851,318.486084 1200.420654,320.510345 1200.409668,327.121948 
C1200.406616,328.937042 1200.538452,330.752350 1200.822388,333.357147 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M822.836121,310.662964 
C822.895386,323.563965 822.895386,336.031799 822.895386,349.042389 
C811.848022,350.170166 800.830627,351.620972 789.961426,347.986755 
C782.529785,345.501923 779.204407,340.891296 778.899658,333.147400 
C778.762207,329.655273 778.761902,326.151123 778.866638,322.657440 
C779.128357,313.926483 784.453491,308.186920 793.284058,308.146759 
C798.975098,308.120850 804.672058,309.395447 810.681213,310.125427 
C809.246887,303.755646 803.019836,305.639801 798.622559,303.789001 
C798.808289,301.706329 798.995605,299.605988 799.177917,297.561523 
C810.505676,295.061920 820.087952,300.264404 822.836121,310.662964 
M793.828796,320.209717 
C789.682495,323.627563 791.302246,328.256287 791.748901,332.313934 
C792.400635,338.235748 798.260010,340.779114 807.431030,339.868439 
C813.573792,339.258453 810.109070,334.593353 810.772034,331.771851 
C811.254333,329.719086 811.064209,327.434570 810.780640,325.305756 
C810.631409,324.185547 809.798340,322.411194 809.000549,322.244904 
C804.234070,321.251495 799.387878,320.640503 793.828796,320.209717 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1008.243408,310.789032 
C1012.046204,299.414032 1020.920776,294.780396 1034.241211,296.857758 
C1044.806396,298.505432 1050.831421,305.709198 1050.815674,317.052643 
C1050.807129,323.179169 1051.101318,329.434814 1050.008179,335.403076 
C1048.013550,346.292328 1038.880493,351.355225 1025.388184,349.975494 
C1015.004822,348.913696 1007.986755,341.481232 1007.867065,331.133606 
C1007.790283,324.491913 1008.034790,317.846497 1008.243408,310.789032 
M1020.158203,326.383026 
C1020.159912,328.043671 1020.078918,329.709320 1020.178589,331.364075 
C1020.503113,336.750916 1023.526245,339.223694 1029.594116,339.149017 
C1035.347046,339.078217 1038.056274,336.611969 1038.136963,331.197266 
C1038.211182,326.216400 1038.191162,321.233124 1038.143188,316.251587 
C1038.083618,310.078125 1036.155518,308.053314 1030.281372,307.849701 
C1023.572083,307.617126 1020.566650,309.958313 1020.201782,315.964111 
C1020.010803,319.107971 1020.161804,322.272552 1020.158203,326.383026 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M688.991760,337.981720 
C688.991760,324.351471 688.991760,311.219116 688.991760,297.116425 
C698.438354,297.116425 707.692932,296.503357 716.828308,297.284515 
C726.447998,298.107086 731.485962,304.495178 731.563049,314.216309 
C731.653870,325.663879 731.584351,337.112762 731.584351,348.861023 
C727.239746,348.861023 723.359924,348.861023 718.991516,348.861023 
C718.991516,338.591125 718.558655,328.462463 719.119751,318.389160 
C719.626953,309.283356 712.273926,305.112488 703.468567,308.581604 
C702.331665,309.029480 701.293335,311.298004 701.267090,312.742371 
C701.079834,323.053345 701.167053,333.369324 701.167053,343.683685 
C701.167053,345.315735 701.167053,346.947784 701.167053,348.865112 
C697.028442,348.865112 693.297729,348.865112 688.991760,348.865112 
C688.991760,345.368958 688.991760,341.924316 688.991760,337.981720 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1097.992798,335.991547 
C1097.992798,323.026947 1097.992798,310.561371 1097.992798,297.119476 
C1107.432861,297.119476 1116.688599,296.483307 1125.815674,297.293976 
C1135.418945,298.146973 1140.484375,304.591095 1140.560425,314.221985 
C1140.650879,325.668304 1140.581787,337.115906 1140.581787,348.861633 
C1136.237793,348.861633 1132.358521,348.861633 1127.992310,348.861633 
C1127.992310,338.589752 1127.542358,328.459961 1128.130615,318.390778 
C1128.627075,309.889862 1121.894043,304.912903 1112.443726,308.567719 
C1111.260010,309.025513 1110.290405,311.617676 1110.265137,313.237091 
C1110.082153,325.009613 1110.166748,336.786316 1110.166748,348.861298 
C1106.032471,348.861298 1102.302002,348.861298 1097.992798,348.861298 
C1097.992798,344.705811 1097.992798,340.598175 1097.992798,335.991547 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M413.103821,311.429657 
C413.144531,324.025757 413.144531,336.169678 413.144531,348.736023 
C409.140137,348.736023 405.285614,348.736023 400.840851,348.736023 
C400.840851,338.802887 400.856018,328.873474 400.836426,318.944153 
C400.817993,309.613586 395.640991,305.677765 386.610352,307.940125 
C383.979370,308.599243 382.873962,309.734406 382.921875,312.689392 
C383.115570,324.637939 383.002228,336.591431 383.002228,348.849121 
C378.924469,348.849121 375.200470,348.849121 371.155334,348.849121 
C371.155334,331.886536 371.155334,314.994812 371.155334,297.100555 
C380.198669,297.100555 389.280060,296.577759 398.271667,297.252075 
C406.744415,297.887451 411.591583,303.032318 413.103821,311.429657 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M467.051331,276.989258 
C469.493439,276.989288 471.462219,276.989288 473.970215,276.989288 
C473.970215,283.864655 473.970215,290.421295 473.970215,297.338257 
C477.791168,297.338257 481.068268,297.338257 484.353973,297.338257 
C484.049072,307.128296 483.480865,307.746490 474.110107,308.190369 
C474.110107,317.713409 473.701691,327.312958 474.418518,336.827789 
C474.582581,339.005585 478.623810,340.891327 481.656555,342.072632 
C481.723480,342.376312 481.954651,342.812836 481.832611,342.963745 
C479.933411,345.312500 481.305603,350.152893 476.616943,350.304077 
C470.231934,350.509979 464.341644,347.001282 462.482239,341.941132 
C461.748108,339.943359 461.218903,337.739838 461.208435,335.626923 
C461.112793,316.351440 461.152252,297.075348 461.152252,276.989258 
C463.102692,276.989258 464.840363,276.989258 467.051331,276.989258 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M881.640137,277.115295 
C882.349426,281.960724 882.628052,286.710693 882.842407,291.463562 
C882.923218,293.254547 882.855469,295.052216 882.855469,297.132477 
C886.553650,297.330170 889.780640,297.502686 893.586121,297.706085 
C892.714539,301.341705 891.957275,304.500244 891.135498,307.927795 
C888.409546,307.927795 885.964966,307.927795 882.984314,307.927795 
C882.984314,317.862885 882.576904,327.489227 883.297241,337.030457 
C883.458618,339.168610 887.568909,341.008698 890.560059,342.201141 
C890.611023,342.342285 890.749512,342.539398 890.700195,342.616455 
C889.033264,345.223450 887.559021,349.828369 885.625916,350.030884 
C880.313904,350.587463 874.970276,348.807343 872.022766,343.534698 
C870.998535,341.702667 870.158447,339.471954 870.144165,337.416992 
C870.005920,317.459137 870.061035,297.499878 870.061035,277.021027 
C873.960938,277.021027 877.572327,277.021027 881.640137,277.115295 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M641.340698,307.783630 
C632.114868,306.534241 627.906128,310.035217 627.874573,318.663391 
C627.838196,328.621613 627.866150,338.580017 627.866150,348.854858 
C623.531799,348.854858 619.634705,348.854858 615.434387,348.854858 
C615.434387,331.834534 615.434387,314.936859 615.434387,297.374084 
C626.005676,297.374084 636.433533,297.374084 646.861450,297.374084 
C647.097656,297.819794 647.333801,298.265533 647.570007,298.711243 
C644.618225,301.219757 648.865845,307.590363 641.340698,307.783630 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1293.062500,347.810242 
C1289.161743,348.681335 1285.327637,349.071106 1281.141479,349.496674 
C1281.141479,325.035034 1281.141479,301.332367 1281.141479,277.315002 
C1284.895142,277.315002 1288.641479,277.315002 1293.129028,277.315002 
C1293.129028,300.594879 1293.129028,323.961914 1293.062500,347.810242 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M940.978699,303.160461 
C940.978699,301.195068 940.978699,299.706909 940.978699,297.804169 
C945.072449,297.804169 948.939819,297.804169 953.212646,297.804169 
C953.212646,314.670624 953.212646,331.535370 953.212646,348.792999 
C949.368347,348.792999 945.490234,348.792999 940.978699,348.792999 
C940.978699,333.794098 940.978699,318.715881 940.978699,303.160461 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M318.021240,309.021240 
C318.021240,322.474304 318.021240,335.431854 318.021240,348.785339 
C313.927917,348.785339 310.049896,348.785339 305.776550,348.785339 
C305.776550,331.994995 305.776550,315.133911 305.776550,297.828918 
C309.597412,297.828918 313.468689,297.828918 318.021240,297.828918 
C318.021240,301.348083 318.021240,304.936920 318.021240,309.021240 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M954.036011,282.751587 
C953.792114,283.594574 953.683350,284.109375 953.398315,284.493378 
C951.207825,287.445007 948.509338,288.949158 944.833374,287.244751 
C942.007202,285.934357 939.716003,283.595093 941.199280,280.678345 
C942.255859,278.600616 945.126648,276.522705 947.428772,276.207947 
C951.306519,275.677795 953.954407,278.038696 954.036011,282.751587 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M305.554504,280.509766 
C306.411469,279.273285 306.973114,278.189453 307.847504,277.505493 
C311.011322,275.030792 315.200195,275.569519 317.551208,278.687866 
C319.847961,281.734222 318.805756,284.317657 316.171143,286.457397 
C313.182831,288.884308 308.692902,288.252747 306.547577,284.987854 
C305.798889,283.848389 305.763733,282.240112 305.554504,280.509766 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="0.000000"
      stroke="none"
      d="
M752.844971,42.001999 
C752.844971,36.524986 752.844971,31.547361 752.844971,26.238247 
C754.495178,26.154318 755.940796,26.018085 757.386475,26.016832 
C774.547852,26.001949 791.709290,26.005594 808.870667,26.007568 
C830.573547,26.010067 844.122253,36.498955 848.464966,57.752880 
C851.711609,73.642052 851.129639,89.575836 845.165222,104.909752 
C839.623535,119.156593 828.773376,126.537369 813.712341,126.891708 
C794.063110,127.353981 774.396973,127.097557 754.738098,127.137306 
C754.271301,127.138252 753.804199,126.984619 752.844971,126.817108 
C752.844971,98.702065 752.844971,70.601730 752.844971,42.001999 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="0.000000"
      stroke="none"
      d="
M371.151550,122.000061 
C371.151550,134.821823 371.319794,147.147049 371.066833,159.463623 
C370.968781,164.238510 370.257294,169.068268 369.231842,173.742859 
C366.965515,184.074112 360.803711,191.528931 350.757202,194.862457 
C343.412231,197.299576 335.732849,199.740143 328.103943,200.102402 
C311.546326,200.888611 294.925568,200.344849 278.089111,200.344849 
C278.089111,142.319351 278.089111,84.275406 278.089111,25.842314 
C279.148346,25.738279 280.230743,25.534346 281.311920,25.540468 
C296.790466,25.628107 312.329285,24.915173 327.733734,26.038517 
C353.866302,27.944191 371.019196,47.279240 371.138580,73.545219 
C371.211243,89.529854 371.151703,105.515091 371.151550,122.000061 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="0.000000"
      stroke="none"
      d="
M648.552002,22.015341 
C674.403503,26.990650 686.137390,41.215668 686.148132,67.120644 
C686.160767,97.759842 685.824280,128.403519 686.242798,159.037003 
C686.663025,189.796524 666.165833,201.929260 643.864990,202.874207 
C635.892029,203.212036 627.457825,202.230316 619.856873,199.856674 
C602.111267,194.315018 592.634827,180.564117 592.561096,161.903488 
C592.432739,129.433090 592.377625,96.960945 592.572266,64.491219 
C592.727905,38.537487 609.619385,22.051441 636.143616,21.556099 
C640.124084,21.481764 644.112488,21.830940 648.552002,22.015341 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="0.000000"
      stroke="none"
      d="
M449.439087,33.425385 
C464.449341,18.159969 497.691193,18.424372 513.272583,28.994675 
C525.351501,37.188915 530.747009,48.993645 530.885010,63.012150 
C531.212769,96.324837 531.181763,129.644409 530.923828,162.958328 
C530.749878,185.426102 515.021851,201.269196 491.255219,202.793228 
C483.727753,203.275925 475.817413,202.894821 468.533234,201.089447 
C448.202850,196.050568 437.351013,181.846237 437.227631,160.954926 
C437.039734,129.139343 437.039795,97.320908 437.250732,65.505608 
C437.329773,53.580994 440.191650,42.404648 449.439087,33.425385 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="0.000000"
      stroke="none"
      d="
M1156.837402,67.249191 
C1163.427979,87.289932 1169.914429,106.942917 1176.519897,126.956116 
C1156.303345,126.956116 1136.696045,126.956116 1116.296509,126.956116 
C1126.170288,96.490181 1135.943359,66.335007 1146.107056,34.974598 
C1149.950562,46.508141 1153.341919,56.684780 1156.837402,67.249191 
z"
    />
    <path
      fill="#FF9809"
      opacity="1.000000"
      stroke="none"
      d="
M555.625122,311.906128 
C555.768616,314.595215 555.768616,316.895538 555.768616,319.654419 
C550.726685,319.958923 545.681763,320.263641 540.237793,320.592468 
C539.986877,316.068695 538.169067,311.473846 543.255737,308.559509 
C547.256226,306.267426 552.265625,307.565704 555.625122,311.906128 
z"
    />
    <path
      fill="#FF9A0D"
      opacity="1.000000"
      stroke="none"
      d="
M1200.715332,332.962341 
C1200.538452,330.752350 1200.406616,328.937042 1200.409668,327.121948 
C1200.420654,320.510345 1202.771851,318.486084 1209.233887,319.484589 
C1210.376465,319.661133 1211.522583,319.854340 1212.641479,320.139801 
C1219.654663,321.928986 1219.862915,322.209961 1219.863892,329.441772 
C1219.864258,332.572174 1219.863892,335.702545 1219.863892,338.716156 
C1211.404541,341.741302 1203.748535,339.503021 1200.715332,332.962341 
z"
    />
    <path
      fill="#FF9502"
      opacity="1.000000"
      stroke="none"
      d="
M1219.087402,309.928802 
C1219.168213,310.075378 1219.045654,310.433380 1218.719482,310.694275 
C1218.638428,310.444885 1218.761230,310.292542 1219.087402,309.928802 
z"
    />
    <path
      fill="#FF9A0D"
      opacity="1.000000"
      stroke="none"
      d="
M794.198608,320.055450 
C799.387878,320.640503 804.234070,321.251495 809.000549,322.244904 
C809.798340,322.411194 810.631409,324.185547 810.780640,325.305756 
C811.064209,327.434570 811.254333,329.719086 810.772034,331.771851 
C810.109070,334.593353 813.573792,339.258453 807.431030,339.868439 
C798.260010,340.779114 792.400635,338.235748 791.748901,332.313934 
C791.302246,328.256287 789.682495,323.627563 794.198608,320.055450 
z"
    />
    <path
      fill="#FF9706"
      opacity="1.000000"
      stroke="none"
      d="
M1020.158264,325.905396 
C1020.161804,322.272552 1020.010803,319.107971 1020.201782,315.964111 
C1020.566650,309.958313 1023.572083,307.617126 1030.281372,307.849701 
C1036.155518,308.053314 1038.083618,310.078125 1038.143188,316.251587 
C1038.191162,321.233124 1038.211182,326.216400 1038.136963,331.197266 
C1038.056274,336.611969 1035.347046,339.078217 1029.594116,339.149017 
C1023.526245,339.223694 1020.503113,336.750916 1020.178589,331.364075 
C1020.078918,329.709320 1020.159912,328.043671 1020.158264,325.905396 
z"
    />
  </svg>
);

export default AppLogo;
