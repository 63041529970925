import { FC } from 'react';
import { PopupButton } from '@typeform/embed-react';
import Modal from 'react-modal';
import './JobDetailModal.scss';

const JobDetailView: FC<{
  iconName: string;
  title: String;
  value: string;
}> = ({ iconName, title, value }) => (
  <div className="col-6 col-lg-4 mb-3 mb-lg-0 job-detail-view d-flex flex-column align-items-center">
    <div className="d-flex w-100 justify-content-center">
      <i className={'bi ' + iconName} />
      <div className="ms-2">{title}</div>
    </div>
    <div className="value mt-2">{value}</div>
  </div>
);

const JobPoints: FC<{ points?: string[]; title: string }> = ({
  points,
  title,
}) => {
  if ((points ?? []).length === 0) return null;

  return (
    <div className="job-points-view w-100">
      <div className="title">{title}</div>
      <ul>
        {(points ?? []).map((text) => (
          <li>{text}</li>
        ))}
      </ul>
    </div>
  );
};

Modal.setAppElement('#root');

const JobDetailModal: FC<{ job?: Job; onModalClose: () => void }> = ({
  job,
  onModalClose,
}) => {
  return (
    <Modal
      isOpen={job ? true : false}
      className={'job-detail-modal d-flex flex-column align-items-center'}
      contentLabel="Example Modal"
    >
      <div className="d-flex flex-column align-items-center w-100">
        <div className="d-flex  w-100">
          <h3 className="text-center px-md-5 text-center w-100">{job?.name}</h3>
          <div
            className="close-button-container ms-3 cursor-pointer"
            onClick={onModalClose}
          >
            <i className="bi bi-x-lg"></i>
          </div>
        </div>
        <PopupButton
          id={process.env.REACT_APP_TYPEFORM_FORM_ID ?? ''}
          className="btn btn-primary mt-3 w-100 apply-button mx-auto my-3"
          hidden={{
            job_profile: job?.name ?? '',
          }}
        >
          Apply
        </PopupButton>
      </div>
      <div className="job-detail-modal-container h-100 w-100 d-flex flex-column align-items-center">
        <div className="row w-100 mt-3 justify-content-center">
          <JobDetailView
            iconName="bi-geo-alt"
            title="Location"
            value={job?.location ?? ''}
          />
          <JobDetailView
            iconName="bi-briefcase"
            title="Experience"
            value={job?.experience ?? ''}
          />
          <JobDetailView
            iconName="bi-buildings"
            title="Type"
            value={job?.type ?? ''}
          />
        </div>
        <div className="divider mb-0" />

        {job?.description && (
          <div className="job-description">{job.description}</div>
        )}

        <JobPoints title="Tasks" points={job?.tasks} />
        <JobPoints title="Role" points={job?.role} />
        <JobPoints title="Profile" points={job?.profile} />
        <JobPoints title="Qualifications" points={job?.qualifications} />
        <JobPoints title="Responsiblities" points={job?.responsibilities} />
        <JobPoints
          title="Skills & Experience"
          points={job?.skillsAndExperience}
        />
        <JobPoints title="Experience With" points={job?.experienceWith} />
        <JobPoints title="Benefits" points={job?.benifits} />
      </div>
    </Modal>
  );
};

export default JobDetailModal;
