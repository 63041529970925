import Fuse from 'fuse.js';

import './JobBoardPage.scss';
import { JobData } from '../../constants/job.constants';
import JobView from './JobView';
import { Routes as RouteConstant } from '../../constants/nav.constant';
import { FC, useEffect, useMemo, useState } from 'react';
import JobSearchBanner from './section/JobSearchBanner';
import JobDetailModal from './job-detail-modal/JobDetailModal';
import { useParams, useNavigate } from 'react-router';
import { JobDataMapper } from '../../constants/job.constants';
import MetaHelmet from '../../components/meta-helmet/MetaHelmet';

const options = {
  threshold: 0.5,
  keys: ['name', 'location', 'experience', 'type'],
};

const JobBoardPage: FC<{}> = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [showJobDetailModal, setShowJobDetailModal] = useState<Job | undefined>(
    () => {
      if (jobId && JobDataMapper[jobId]) {
        return JobDataMapper[jobId];
      }
      return undefined;
    },
  );

  useEffect(() => {
    if (jobId && JobDataMapper[jobId]) {
      setShowJobDetailModal(JobDataMapper[jobId]);
    } else {
      onModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  const filteredJobData = useMemo(() => {
    const fuse = new Fuse<Job>(JobData, options);
    return (searchValue ?? '').trim().length
      ? (fuse.search(searchValue.trim()) ?? []).map((fuseJob) => fuseJob.item)
      : JobData;
  }, [searchValue]);

  const onJobClick = (job: Job) => {
    navigate(RouteConstant.jobBoard + '/' + job.id);
    setShowJobDetailModal(job);
  };
  const onModalClose = () => {
    navigate(RouteConstant.jobBoard);
    setShowJobDetailModal(undefined);
  };

  return (
    <main className="job-board-page container navbar-padding overflow-hidden position-relative">
      <MetaHelmet
        title={
          showJobDetailModal ? showJobDetailModal?.name ?? '' : 'Job Board'
        }
      />
      <JobDetailModal job={showJobDetailModal} onModalClose={onModalClose} />
      <JobSearchBanner
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <div className="job-list row mb-5">
        {(filteredJobData ?? []).length === 0 ? (
          <div className="my-5 text-center">{`No jobs found for "${searchValue}"`}</div>
        ) : (
          (filteredJobData ?? []).map((job) => (
            <JobView job={job} key={job.name} onClick={onJobClick} />
          ))
        )}
      </div>
    </main>
  );
};

export default JobBoardPage;
