import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useLocalStorage } from './useLocalStorage';
import {
  CONSENT_LOCAL_STORAGE,
  CONSENT_OPTION,
} from '../constants/consent.constant';

const usePageTracking = () => {
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [consentValue, setConsentValue, readConsentValue] =
    useLocalStorage<CONSENT_OPTION>(
      CONSENT_LOCAL_STORAGE,
      CONSENT_OPTION.REJECTED,
    );

  useEffect(() => {
    const consent = readConsentValue() === CONSENT_OPTION.ACCEPTED;

    if (consent)
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

export default usePageTracking;
