/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialSection.scss';
import { Testimonials } from '../../../constants/testimonial.constant';

const slickSettings = {
  infinite: true,
  speed: 1000,
  showDots: false,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Slide: FC<Testimonial> = ({ text, name, role }) => (
  <div className="testimonial d-flex flex-column justify-content-between position-relative">
    <q className="text text-center mt-3">{text}</q>
    <div className="mt-4">
      <div className="name text-center">
        {name} <br />
        <span className="role">({role})</span>
      </div>
    </div>
    <div className="quote-parent d-flex justify-content-center align-items-center">
      {' '}
      <i className="bi bi-quote" />
    </div>
  </div>
);

const TestimonialSection = () => {
  const { t } = useTranslation();
  return (
    <section className="testimonial-panel">
      <h3 className="text-center mb-5">
        {t('about.testimonials.title1')}
        <span className="text-primary">{t('about.testimonials.title2')}</span>
      </h3>

      <Slider {...slickSettings}>
        {Testimonials.map((testimonials) => (
          <Slide {...testimonials} key={testimonials.name} />
        ))}
      </Slider>
    </section>
  );
};

export default TestimonialSection;
