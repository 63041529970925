export const Testimonials: Testimonial[] = [
  {
    text: 'Absolutely! We work with top payment companies which guarantees your safety and security.',
    name: 'Rob Harison',
    role: 'CEO, ABC',
  },
  {
    text: 'When you innovate, you make mistakes. It is best to admit them quickly and get on with improving to success.',
    name: 'Jane Ribon',
    role: 'CEO, Bubble',
  },
  {
    text: 'They are truly partnering with us on different initiatives. We are deeply appreciative of all their efforts.',
    name: 'Kate Potter',
    role: 'CEO, Zendesk',
  },
  {
    text: 'I still can’t get over how fast I built my website just copying components from the Ultra template.',
    name: 'Jason Walter',
    role: 'Founder, Fraction',
  },
  {
    text: 'I’m absolutely blown away by the new Ultra template. You have to try it!',
    name: 'Arda Duncan',
    role: 'Web Designer, Inno',
  },
  {
    text: ' still can’t get over how fast I built my website just copying components from the Ultra template.”',
    name: 'Ayisha Cruz',
    role: 'Founder, Ulra',
  },
];
