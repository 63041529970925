export const TeamMembers = [
  {
    name: 'Robert Sadowski',
    role: 'Cofounder -CEO',
    picture: '/assets/images/robert_profile_pic.jpeg',
    linkedIn: 'https://www.linkedin.com/in/robert-sadowski-0690087b/',
  },
  {
    name: 'Deeban Chakravarthi Mathivanan',
    role: 'Cofounder -CEO',
    picture: '/assets/images/deepan_profile_pic.jpeg',
    instagram: '',
    twitter: 'https://twitter.com/DeebanChakrava7',
    linkedIn: 'https://www.linkedin.com/in/deebanc/',
  },
];
