import { FC } from 'react';
import { Helmet } from 'react-helmet';

const MetaHelmet: FC<{
  title?: string;
  description?: string;
  image?: string;
}> = ({
  title,
  description = 'Unlock Your Dream Career in Germany: Discover Opportunities, Excel, and Thrive!',
  image = 'https://doorwayinternational.com/assets/images/app-screenshot.png',
}) => {
  const getTitle = () =>
    title ? title + ' | Doorway International ' : 'Doorway International';
  return (
    <Helmet>
      <title>{getTitle()}</title>

      <meta name="title" content={getTitle()} />
      <meta name="og:title" content={getTitle()} />
      <meta name="twitter:title" content={getTitle()} />

      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      <meta name="image" content={image} />
      <meta name="og:image" content={image} />
      <meta name="twitter:image" content={image} />

      <meta name="twitter:card" content={image ? 'summary' : 'summary'} />
    </Helmet>
  );
};

export default MetaHelmet;
