/* eslint-disable import/no-anonymous-default-export */
export default {
  // translation: {
  //   job: {
  //     STEM: {
  //       title: 'STEM Talent Lösungen',
  //       detail: [
  //         'Sich auf dem MINT-Arbeitsmarkt in Deutschland zurechtzufinden, kann eine schwierige Aufgabe sein, besonders für internationale Bewerber. Wir von Doorway International haben uns auf die Vermittlung von MINT-Fachkräften spezialisiert und bringen talentierte Menschen mit spannenden Karrieremöglichkeiten in Deutschlands florierenden MINT-Sektoren zusammen. Unser Expertenteam kennt die besonderen Herausforderungen, mit denen internationale Bewerber in diesem wettbewerbsintensiven Bereich konfrontiert sind, und bietet maßgeschneiderte Unterstützung während des gesamten Rekrutierungsprozesses.',
  //         'Dank unseres umfangreichen Netzwerks an Branchenkontakten und unserer profunden Kenntnis des deutschen Arbeitsmarkts können wir die am besten geeigneten MINT-Stellen identifizieren, die Ihren Fähigkeiten, Erfahrungen und Karrierezielen entsprechen. Wir gehen über herkömmliche Stellenbörsen hinaus und nutzen unsere Verbindungen zu Top-Unternehmen und Forschungseinrichtungen, um versteckte Möglichkeiten zu entdecken, die der Öffentlichkeit nicht ohne weiteres zugänglich sind. Egal, ob Sie gerade Ihren MINT-Abschluss gemacht haben oder eine erfahrene Fachkraft sind, die nach neuen Horizonten sucht, unsere umfassenden MINT-Rekrutierungsdienstleistungen optimieren Ihre Stellensuche und erhöhen Ihre Chancen auf eine erfüllende Position in Deutschland.',
  //         'Von der Unterstützung bei der Optimierung des Lebenslaufs und der Vorbereitung auf Vorstellungsgespräche bis hin zur Beratung zu Visabestimmungen und kultureller Integration - unser Team unterstützt Sie bei jedem Schritt des Weges. Wir sind uns des immensen Wertes bewusst, den internationale Bewerber in die deutsche MINT-Landschaft einbringen, und unser Ziel ist es, Ihre nahtlose Integration in die pulsierende wissenschaftliche und technologische Gemeinschaft des Landes zu erleichtern. Vertrauen Sie Doorway International als Ihrem Partner, der Ihnen die Türen zu spannenden MINT-Karrieremöglichkeiten in Deutschland öffnet.',
  //       ],
  //     },
  //     Apprentice: {
  //       title: 'Vermittlung von Auszubildenden',
  //       detail: [
  //         'Die renommierte deutsche Ausbildung gehört zu den besten der Welt und besteht zu 60 % aus praktischer und zu 40 % aus theoretischer Ausbildung. Darüber hinaus erhalten die Auszubildenden ein Stipendium von 700 bis 1200 Euro, was eine unglaubliche Chance für die berufliche Entwicklung darstellt. Wir bei Doorway International sind stolz darauf, Ausbildungsplätze mit Stipendien in einer Vielzahl von Bereichen anbieten zu können, darunter IT, Maschinenbau, Mechatronik, Elektrotechnik, Krankenpflege und Gastronomie.',
  //         'Mit unserem umfangreichen Netzwerk von Partnerunternehmen und Bildungseinrichtungen bieten wir angehenden Auszubildenden Zugang zu außergewöhnlichen Ausbildungsmöglichkeiten. Sie können praktische Erfahrungen an der Seite von Fachleuten aus der Branche sammeln und gleichzeitig theoretisches Wissen im Unterricht erwerben. Diese einzigartige Mischung aus praktischem und theoretischem Lernen vermittelt den Auszubildenden die Fähigkeiten und das Wissen, die sie benötigen, um in den von ihnen gewählten Bereichen hervorragende Leistungen zu erbringen.',
  //         'Eine Lehre in Deutschland ist nicht nur eine wertvolle Ausbildung, sondern wird auch finanziell unterstützt. Das Stipendium, das während der Ausbildungszeit gezahlt wird, stellt sicher, dass die Auszubildenden ihren Lebensunterhalt bestreiten und sich gleichzeitig auf ihre berufliche Entwicklung konzentrieren können.',
  //         'Ob Sie nun IT-Spezialist, Maschinenbauingenieur, Elektriker, Krankenpfleger oder Gastronomiefachmann werden wollen, unsere Ausbildungsprogramme öffnen Ihnen die Türen zu spannenden Karrierewegen. Wir von Doorway International unterstützen internationale Bewerber dabei, einen Ausbildungsplatz zu finden, der ihren Interessen und Zielen entspricht.',
  //         'Begeben Sie sich mit unseren Ausbildungsprogrammen in Deutschland auf eine lohnende Reise der persönlichen und beruflichen Entwicklung. Erwerben Sie praktische Fähigkeiten, verdienen Sie ein Stipendium und ebnen Sie den Weg für eine erfolgreiche Zukunft in Ihrem gewählten Bereich. Schließen Sie sich mit Doorway International zusammen, um eine Ausbildung von Weltklasse zu erhalten und eine neue berufliche Laufbahn einzuschlagen.',
  //       ],
  //     },
  //     Healthcare: {
  //       title: 'Experten für Personalvermittlung im Gesundheitswesen',
  //       detail: [
  //         'Werden Sie Teil der Elite im Gesundheitswesen in Deutschland: Erfolgreich als Krankenschwester oder Mitarbeiter im Gesundheitswesen',
  //         'Deutschland bietet außergewöhnliche Möglichkeiten für Krankenschwestern und Krankenpfleger und ist daher ein sehr begehrtes Ziel in der Gesundheitsbranche. Als Krankenschwester oder Fachkraft im Gesundheitswesen können Sie eine lohnende Karriere mit wettbewerbsfähigen Gehältern, hervorragenden Sozialleistungen und einem hohen Standard der Arbeitsbedingungen erwarten.',
  //         'Wir von Doorway International sind darauf spezialisiert, talentierte Krankenschwestern und Pflegekräfte mit erstklassigen Gesundheitseinrichtungen in Deutschland zusammenzubringen. Egal, ob Sie examinierter Krankenpfleger, medizinischer Assistent oder medizinisches Fachpersonal sind, wir bieten Ihnen Möglichkeiten in verschiedenen Einrichtungen des Gesundheitswesens, darunter Krankenhäuser, Kliniken, Pflegeheime und Rehabilitationszentren.',
  //         'Das deutsche Gesundheitssystem ist bekannt für seinen Schwerpunkt auf Patientenversorgung, modernste Medizintechnik und kontinuierliche berufliche Weiterentwicklung. Wenn Sie sich der Elite des Gesundheitswesens in Deutschland anschließen, haben Sie Zugang zu hochmodernen Einrichtungen, fortschrittlichen medizinischen Ressourcen und einem unterstützenden Arbeitsumfeld, das Wachstum und Innovation fördert.',
  //         'Als internationaler Bewerber bieten wir Ihnen umfassende Unterstützung während des gesamten Prozesses, einschließlich Hilfe bei den Visabestimmungen, der sprachlichen Vorbereitung und der kulturellen Integration. Unser Team kennt die besonderen Herausforderungen, mit denen internationale Krankenschwestern und Pflegekräfte konfrontiert sind, und wir setzen uns dafür ein, Ihren Wechsel nach Deutschland so reibungslos wie möglich zu gestalten.',
  //         'Egal, ob Sie eine Festanstellung oder eine befristete Anstellung suchen, Doorway International bietet Ihnen ein breites Spektrum an Möglichkeiten im Pflege- und Gesundheitssektor. Machen Sie den nächsten Schritt in Ihrer Karriere und nutzen Sie die unglaubliche berufliche und persönliche Entwicklung, die Sie in Deutschlands geschätzter Gesundheitsbranche erwartet.',
  //         'Kommen Sie zu Doorway International und begeben Sie sich auf eine erfüllende Reise als ein geschätztes Mitglied des Gesundheitspersonals in Deutschland. Ihre Fähigkeiten, Ihr Engagement und Ihr Mitgefühl werden das Leben der Patienten verändern und Ihnen gleichzeitig eine lohnende und erfolgreiche Karriere ermöglichen.',
  //       ],
  //     },
  //     Resume: {
  //       title: 'Professionelles Schreiben von Lebensläufen',
  //       detail: [
  //         'Doorway International hat es sich zur Aufgabe gemacht, Ihren beruflichen Erfolg zu unterstützen. Als Teil unseres Engagements bieten wir Ihnen einen professionellen und kostenlosen Service zum Schreiben von Lebensläufen. Unser Team aus fachkundigen Karriereberatern und Lebenslaufschreibern wird eng mit Ihnen zusammenarbeiten, um einen überzeugenden Lebenslauf zu erstellen, der Ihre Fähigkeiten, Erfahrungen und Leistungen hervorhebt.',
  //         'Mit unserem fundierten Wissen über den Arbeitsmarkt und die branchenspezifischen Anforderungen sorgen wir dafür, dass sich Ihr Lebenslauf von dem der anderen Bewerber abhebt. Wir passen Ihren Lebenslauf so an, dass er Ihre einzigartigen Stärken und Qualifikationen zur Geltung bringt und Ihre Chancen auf ein Vorstellungsgespräch und Ihren Traumjob erhöht.',
  //         'Wenn Sie unseren kostenlosen Lebenslaufservice in Anspruch nehmen, können Sie sich den Arbeitgebern wirkungsvoll präsentieren und einen bleibenden Eindruck hinterlassen. Wir wissen, wie wichtig ein gut ausgearbeiteter Lebenslauf auf dem heutigen umkämpften Arbeitsmarkt ist, und wir helfen Ihnen, einen starken Eindruck zu hinterlassen.',
  //         'Lassen Sie sich diese wertvolle Gelegenheit zur Verbesserung Ihrer Stellensuche nicht entgehen. Nehmen Sie noch heute Kontakt mit uns auf und nutzen Sie unseren professionellen und kostenlosen Lebenslaufservice. Wir helfen Ihnen bei der Erstellung eines Lebenslaufs, der Ihnen Türen zu aufregenden Karrierechancen öffnet.',
  //       ],
  //     },
  //     Career: {
  //       title: 'Coaching für den beruflichen Aufstieg',
  //       detail: [
  //         'Doorway International hat es sich zur Aufgabe gemacht, Ihren beruflichen Erfolg zu unterstützen. Als Teil unseres Engagements bieten wir Ihnen einen professionellen und kostenlosen Service zum Schreiben von Lebensläufen. Unser Team aus fachkundigen Karriereberatern und Lebenslaufschreibern wird eng mit Ihnen zusammenarbeiten, um einen überzeugenden Lebenslauf zu erstellen, der Ihre Fähigkeiten, Erfahrungen und Leistungen hervorhebt.',
  //         'Mit unserem fundierten Wissen über den Arbeitsmarkt und die branchenspezifischen Anforderungen sorgen wir dafür, dass sich Ihr Lebenslauf von dem der anderen Bewerber abhebt. Wir passen Ihren Lebenslauf so an, dass er Ihre einzigartigen Stärken und Qualifikationen zur Geltung bringt und Ihre Chancen auf ein Vorstellungsgespräch und Ihren Traumjob erhöht.',
  //         'Wenn Sie unseren kostenlosen Lebenslaufservice in Anspruch nehmen, können Sie sich den Arbeitgebern wirkungsvoll präsentieren und einen bleibenden Eindruck hinterlassen. Wir wissen, wie wichtig ein gut ausgearbeiteter Lebenslauf auf dem heutigen umkämpften Arbeitsmarkt ist, und wir helfen Ihnen, einen starken Eindruck zu hinterlassen.',
  //         'Lassen Sie sich diese wertvolle Gelegenheit zur Verbesserung Ihrer Stellensuche nicht entgehen. Nehmen Sie noch heute Kontakt mit uns auf und nutzen Sie unseren professionellen und kostenlosen Lebenslaufservice. Wir helfen Ihnen bei der Erstellung eines Lebenslaufs, der Ihnen Türen zu aufregenden Karrierechancen öffnet.',
  //       ],
  //     },
  //     Branding: {
  //       title: 'Employer Branding Lösungen',
  //       detail: [
  //         'Heben Sie Ihre Arbeitgebermarke mit maßgeschneiderten Lösungen hervor',
  //         'Wir von Doorway International wissen, wie wichtig eine starke Arbeitgebermarke für die Gewinnung und Bindung von Spitzenkräften ist. Unsere Employer-Branding-Lösungen sollen Ihrem Unternehmen helfen, sich auf dem umkämpften Arbeitsmarkt zu profilieren und sich als bevorzugter Arbeitgeber zu positionieren.',
  //         'Wir arbeiten eng mit Ihrem Unternehmen zusammen, um eine umfassende Employer-Branding-Strategie zu entwickeln, die auf Ihre Werte, Kultur und Ziele abgestimmt ist. Unser Expertenteam führt eingehende Untersuchungen durch, um Ihre Alleinstellungsmerkmale und Unterscheidungsmerkmale zu verstehen und eine überzeugende Arbeitgebermarkenbotschaft zu entwickeln, die bei Ihrer Zielgruppe Anklang findet.',
  //         'Mit unseren maßgeschneiderten Lösungen verbessern wir die Sichtbarkeit Ihrer Arbeitgebermarke über verschiedene Kanäle, einschließlich sozialer Medien, Jobbörsen und Karriere-Websites. Wir erstellen ansprechende Inhalte, die Ihre Unternehmenskultur, Ihre Werte, Erfahrungsberichte von Mitarbeitern und Karrieremöglichkeiten präsentieren und Ihre Arbeitgebermarke wirkungsvoll vermitteln.',
  //         'Unsere Employer-Branding-Lösungen gehen über die reine Gewinnung von Talenten hinaus. Wir konzentrieren uns auch auf das Engagement und die Bindung von Mitarbeitern und implementieren Strategien zur Schaffung eines positiven und integrativen Arbeitsumfelds. Durch die Förderung einer starken Arbeitgebermarke können Sie Top-Talente anziehen, die Loyalität Ihrer Mitarbeiter erhöhen und den Ruf Ihres Unternehmens insgesamt verbessern.',
  //         'Whether you are a small startup or a large organization, our employer branding solutions are customizable to meet your specific needs and budget. We are committed to partnering with you to strengthen your employer brand and create a compelling narrative that will resonate with both current and future employees.',
  //         'Lassen Sie Doorway International Ihr zuverlässiger Partner bei der Entwicklung und Umsetzung effektiver Employer-Branding-Lösungen sein. Gemeinsam werden wir Ihre Arbeitgebermarke aufwerten, Top-Talente anziehen und den Erfolg Ihres Unternehmens fördern.',
  //       ],
  //     },
  //   },
  //   home: {
  //     intro: {
  //       title1: 'Starten Sie Ihre Traumkarriere in Deutschland:',
  //       title2:
  //         ': Nutzen Sie Ihre Chancen, übertreffen Sie sich und machen Sie das Beste aus ihrem Beruf!',
  //       description:
  //         'Sie streben nach einer erfolgreichen Karriere auf dem deutschen Arbeitsmarkt? Suchen Sie nicht weiter! Unsere Plattform ist darauf ausgerichtet, ehrgeizigen und qualifizierten Leuten wie Ihnen dabei zu helfen, ihre Traumkarriere in Deutschland zu starten und Ihnen die Türen zu einer Welt voller aufregender Möglichkeiten zu öffnen.',
  //       discoverJobButton: 'Jobportal',
  //       discoverTalentButton: 'Top-Mitarbeiter finden',
  //     },
  //     service: {
  //       title1: 'Was wir tun',
  //       title2: '',
  //       description:
  //         'Starten Sie Ihre Traumkarriere in Deutschland mit fachkundiger Unterstützung bei der Jobsuche, Bewerbungsmanagement und Integrationscoaching. Navigieren Sie mühelos durch den deutschen Arbeitsmarkt und sichern Sie sich den perfekten Job mit unserem, auf Sie abgestimmten Service.',
  //     },
  //     successStory: {
  //       title1: 'Ihr Weg zum Erfolg: ',
  //       title2: 'Chancen eröffnen',
  //       title3:
  //         ' für internationale Kandidaten – Recruiting, Beratung und Coaching',
  //       description:
  //         'Open Doors to Success: Leading the Way for International Candidates - Premier Job Placement Organization in Germany. Find your dream career with our expert guidance and connections in the German job market.',
  //       consultations: 'consultations',
  //       companies: 'companies',
  //     },
  //   },
  //   about: {
  //     intro: {
  //       title1: 'Unsere ',
  //       title2: 'Geschichte',
  //       detail: [
  //         'Unsere Mission bei Doorway International ist einfach: „Wir bringen internationale Fachkräften mit deutschen Arbeitgebern mit dem Ziel zusammen, eine langfristige und für beide Seiten gewinnbringende berufliche Beziehung aufzubauen.',
  //         'Deeban Mathivanan kann auf eine berufliche und akademische Karriere in Deutschland zurückblicken. Mit einem Master in Elektrotechnik und einem MBA der Business School, der Ottovon-Guericke-Universität Magdeburg ausgestattet, sammelte eine Fülle von Kenntnissen, Fähigkeite nund Erfahrungen. Diese konnte er in seinen beruflichen Tätigkeiten mit renommierten Organisationen wie dem Deutschen Zentrum für Luft und Raumfahrt, der Universität Stuttgart sowie einem Personalvermittlungsunternehmen in Berlin ausbauen. Außerdem bekleidete er verantwortungsvolle Positionen im Ehrenamt. Dazu zählen der Vorsitz des Vereins Magdeburg Indians e.V. oder der die Tätigkeit als Studentenkoordinator der indischen Botschaft.',
  //         'Trotzdem war eine Erfahrung stets in seinem Hinterkopf, die ihn weiterhin beschäftigte. In seiner Zeit als internationaler Absolvent in Deutschland stieß er bei der Suche nach einer Beschäftigungsmöglichkeit auf zahlreiche Hindernisse. Von diesen Erfahrungen geprägt, war ihm immer bewusst, dass die Grundvoraussetzungen ausländischer Bewerber verbessert werden muss.',
  //         'Heute ist die Gründungsgeschichte von Deeban Mathivanan und Robert Sadowski ein Zeugnis für die Kraft von Widerstandsfähigkeit, Fachwissen und einer gemeinsamen Vision. Doorway International gestaltet weiterhin die Schicksale internationaler Kandidaten und lädt sie ein, einen bemerkenswerten Weg zum Erfolg einzuschlagen.',
  //       ],
  //     },
  //     mission: {
  //       title1: 'Unser ',
  //       title2: 'Auftrag',
  //       statement:
  //         'Unsere Vision bei Doorway International ist es, Menschen aus der ganzen Welt zu fördern, indem wir sie mit sinnvollen Karrieremöglichkeiten in Verbindung bringen und gleichzeitig mit Unternehmen zusammenarbeiten, um vielfältige und leistungsstarke Teams aufzubauen.',
  //     },
  //     vision: {
  //       title1: 'Unsere ',
  //       title2: 'Werte',
  //       statement:
  //         'Excellence, Integrity, Empowerment, Diversity & Inclusion, Collaboration, Innovation, Impact - Diese Kernwerte treiben unsere Mission an, internationalen Kandidaten zu helfen, sich in einem fremden Land zu integrieren, ihren Traumjob zu finden und Unternehmen zu ermöglichen, außergewöhnliche Talente zu entdecken.',
  //     },
  //     team: {
  //       title1: 'unser  ',
  //       title2: 'Team',
  //     },
  //     testimonials: {
  //       title1: 'unsere ',
  //       title2: 'Zeugnisse',
  //     },
  //   },
  //   service: {
  //     title: 'Was tun wir?',
  //     description:
  //       'Wir bieten persönliche Unterstützung bei der Stellensuche, fachkundiges Coaching und maßgeschneiderte Ressourcen, um Ihnen zu helfen, Ihren Traumjob in Deutschland zu finden. Unser engagiertes Team setzt sich dafür ein, Ihre Karriere voranzutreiben und eine reibungslose Integration in den deutschen Arbeitsmarkt zu gewährleisten.',
  //   },
  //   jobBoard: 'Jobbörse',
  //   contact: 'Kontakt',
  //   today: '',
  //   ConstactDesc1: 'We’re always here to help.',
  //   ConstactDesc2: 'Contact us if you have any questions.',
  //   menu: {
  //     home: 'Startseite',
  //     about: 'Über uns',
  //     services: 'Service',
  //     jobBoard: 'Jobbörse',
  //     contact: 'Kontakt',
  //   },
  //   footer: {
  //     contactInfo: 'Kontaktinformationen',
  //   },
  // },
};
