import { Formik } from 'formik';
import * as Yup from 'yup';
import './ContactFormSection.scss';
import { useState } from 'react';

const ContactFormSection = () => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const onSubmit = async (values: {
    fullName: string;
    email: string;
    phoneNumber: string;
    subject: string;
    message: string;
  }) => {
    try {
      setFormLoading(true);
      const formData = {
        ...values,
        access_key: process.env.REACT_APP_WEB3FORM_FORM_ID ?? '',
      };

      const res = await fetch('https://api.web3forms.com/submit', {
        headers: new Headers({ 'content-type': 'application/json' }),
        method: 'POST',
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .catch(() => {
          alert('unable to submit form');
          setFormLoading(false);
        });

      if (res.success) {
        setFormSubmitted(true);
        setFormLoading(false);
      } else {
        alert('unable to submit form');
        setFormLoading(false);
      }
    } catch (error) {
      alert('unable to submit form');
      setFormLoading(false);
    }
  };
  return (
    <div className="contact-info-form navbar-padding col-12 col-lg-6 d-flex align-items-center justify-content-center mb-5 mb-lg-0">
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          phoneNumber: '',
          subject: '',
          message: '',
        }}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().required('Required'),
          email: Yup.string().email().required('Required'),
          subject: Yup.string().required('Required'),
          message: Yup.string().required('Required'),
        })}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          dirty,
        }) => {
          return (
            <>
              <div className="position-relative">
                <form onSubmit={handleSubmit}>
                  <div className="card p-4 p-lg-5 w-100 mb-5">
                    <div className="row">
                      <div className="form-floating mb-3 col-12">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.fullName && touched.fullName
                              ? 'input-error'
                              : ''
                          } `}
                          id="floating-input-name"
                          name="fullName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Full Name"
                          value={values?.fullName ?? ''}
                        />
                        <label htmlFor="floating-input-name">Full Name *</label>
                        {errors.fullName && touched.fullName && (
                          <div className="input-error-text">
                            {errors.fullName}
                          </div>
                        )}
                      </div>

                      <div className="form-floating mb-3 col-12 col-md-6">
                        <input
                          type="email"
                          className={`form-control ${
                            errors.email && touched.email ? 'input-error' : ''
                          } `}
                          id="floating-input-email"
                          name="email"
                          maxLength={256}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Email address"
                          value={values?.email ?? ''}
                        />
                        <label htmlFor="floating-input-email">
                          Email address *
                        </label>
                        {errors.email && touched.email && (
                          <div className="input-error-text">{errors.email}</div>
                        )}
                      </div>

                      <div className="form-floating mb-3 col-12 col-md-6">
                        <input
                          type="tel"
                          className="form-control"
                          id="floating-input-phone-number"
                          maxLength={256}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="phoneNumber"
                          placeholder="Phone number"
                          value={values?.phoneNumber ?? ''}
                        />
                        <label htmlFor="floating-input-phone-number">
                          Phone number
                        </label>
                      </div>

                      <div className="form-floating mb-3 col-12">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.subject && touched.subject
                              ? 'input-error'
                              : ''
                          } `}
                          id="floating-input-subject"
                          name="subject"
                          maxLength={256}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Subject"
                          value={values?.subject ?? ''}
                        />
                        <label htmlFor="floating-input-subject">
                          Subject *
                        </label>
                        {errors.subject && touched.subject && (
                          <div className="input-error-text">
                            {errors.subject}
                          </div>
                        )}
                      </div>

                      <div className="form-floating mb-3">
                        <textarea
                          className={`form-control h-auto ${
                            errors.message && touched.message
                              ? 'input-error'
                              : ''
                          } `}
                          id="floating-input-message"
                          maxLength={5000}
                          name="message"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          rows={5}
                          placeholder="Message"
                          value={values?.message ?? ''}
                        />
                        <label htmlFor="floating-input-message">
                          Message *
                        </label>
                        {errors.message && touched.message && (
                          <div className="input-error-text">
                            {errors.message}
                          </div>
                        )}
                      </div>

                      <div className="col-12">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          value="Send Message"
                          data-wait="Please wait..."
                          id="w-node-c8f9cbb2-e1a3-78ab-8775-c6c59d116bef-827316f1"
                          className="btn btn-primary w-button px-5 w-100"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                {formSubmitted && (
                  <div className="card fixed-position-full bg-white success-container d-flex flex-column align-items-center justify-content-center">
                    <div className="check-container d-flex justify-content-center align-items-center">
                      <i className="bi bi-check-lg text-white" />
                    </div>
                  </div>
                )}
                {formLoading && (
                  <div className="card fixed-position-full bg-white success-container d-flex flex-column align-items-center justify-content-center">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    />
                  </div>
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ContactFormSection;
