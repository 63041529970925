import { useTranslation } from 'react-i18next';
import './AboutSection.scss';

const AboutSection = () => {
  const { t } = useTranslation();
  const aboutMessage = t('about.intro.detail', { joinArrays: '\n\n' });
  return (
    <section className="about-panel d-flex flex-row-reverse position-relative">
      <img
        alt="About"
        className="about-image d-none d-lg-block"
        src={window.location.origin + '/assets/images/about-banner.webp'}
      />
      <div className="about-content navbar-padding d-flex align-items-center">
        <div className="col-12 col-lg-8 my-4 ">
          <h3 className="text-start my-3 ">
            {t('about.intro.title1')}
            <span className="text-primary">{t('about.intro.title2')}</span>
          </h3>
          <div className="description text-justify text-lg-start">
            {aboutMessage}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
