import { FC } from 'react';
import './ContactUsPage.scss';
import ContactInfoSection from './section/ContactInfoSection';
import ContactFormSection from './section/ContactFormSection';
import MetaHelmet from '../../components/meta-helmet/MetaHelmet';

const ContactUsPage: FC = () => {
  return (
    <div className="contact-us-page  bg-primary-op">
      <MetaHelmet title="Contact Us" />
      <div className="container row mx-auto h-100">
        <ContactInfoSection />
        <ContactFormSection />
      </div>
    </div>
  );
};

export default ContactUsPage;
