import { Dispatch, FC, SetStateAction } from 'react';
import './JobSearchBanner.scss';

const JobSearchBanner: FC<{
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}> = ({ searchValue, setSearchValue }) => (
  <section className="search-banner py-5 px-3 d-flex flex-column align-items-center justify-content-center mb-4">
    <h3 className="text-center mb-5">
      Find The <span className="text-primary">Job</span> That
      <br />
      Fits Your <span className="text-primary">Life</span>
    </h3>
    <div className="search-input-container p-3 w-100">
      <input
        className="search-input w-100"
        name="search"
        onChange={(event) => setSearchValue(event.target.value)}
        placeholder="Search for jobs..."
        type="text"
        value={searchValue ?? ''}
      />
    </div>
  </section>
);

export default JobSearchBanner;
